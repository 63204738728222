import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    gridContainer: {
        display: 'grid',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr 740px 1fr',
        },
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 24,
    },
    link: {
        justifySelf: 'center',
        textDecoration: 'none',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '140%',
        color: '#60A4F6',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: -35,
    },
    icon: { marginRight: 8 },
}));
