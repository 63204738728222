import { makeStyles } from '@material-ui/core';
import { theme } from '../../../../config/theme';

export const useStyles = makeStyles({
    container: {
        [theme.breakpoints.up(960)]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            flexGrow: 1,
        },
    },
    textWrapper: {
        [theme.breakpoints.up(960)]: {
            maxWidth: 1000,
            maxHeight: '80vh',
            overflowY: 'auto',
        },
    },

    subtitle: {
        fontWeight: 'bold',
        fontSize: 16,
        width: '100%',
        marginBottom: 16,
    },
    section: {
        marginBottom: 40,
        maxWidth: 'calc(100vw - 52px)',
    },
});
