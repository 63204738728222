import React, { FC } from 'react';
import { useStyles } from './styles';

const PrivacyPolicy: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.textWrapper}>
                <div className={classes.section}>
                    <p>
                        Thank you for choosing to be part of our community at Just Dormant Accounts Ltd
                        (&quot;Company&quot;, &quot;we&quot;, &quot;us&quot;, &quot;our&quot;). We are
                        committed to protecting your personal information and your right to privacy. It you
                        have any questions or concerns about this privacy notice, or our practices with
                        regards to your personal information, please contact us at{' '}
                        <a target="_blank" rel="noreferrer" href="mailto:contact@justdormantaccounts.co.uk">
                            contact@justdormantaccounts.co.uk
                        </a>
                        .
                    </p>
                    <p>
                        When you visit our website (the &quot;Website&quot;), and more generally, use any of
                        our services (the &quot;Services&quot;, which include the Website), we appreciate that
                        you are trusting us with your personal information. We take your privacy very
                        seriously. In this privacy notice, we seek to explain to you in the clearest way
                        possible what information we collect, how we use it and what rights you nave in
                        relation to it. We hope you take some time to read through it carefully, as it is
                        important. If there are any terms in this privacy notice that you do not agree with,
                        please discontinue use of our Services immediately.
                    </p>
                    <p>
                        This privacy notice applies to all information collected through our Services (which,
                        as described above, includes our Website), as well as, any related services, sales,
                        marketing prevents.
                    </p>
                    <p>
                        Please read this privacy notice carefully as it will help you understand what we do
                        with the information that we collect.
                    </p>
                </div>

                <div className={classes.section}>
                    <p className={classes.subtitle}>WHAT INFORMATION DO WE COLLECT?</p>
                    <p className={classes.subtitle}>Information automatically collected</p>
                    <p>
                        <i>
                            In Short: Some information — such as your Internet Protocol address and/or browser
                            and device characteristics — is collected automatically when you visit our
                            Website.
                        </i>
                    </p>
                    <p>
                        We automatically collect certain information when you visit, use or navigate the
                        Website. This information does not reveal your specific identity (like your name or
                        contact information)but may include device and usage information, such as your IP
                        address, browser and device characteristics, operating system, language preferences,
                        referring URLs, device name, country, location, information about now and when you use
                        our Website and other technical information. This information is primarily needed to
                        maintain the security and operation of our Website, and for our internal analytics and
                        reporting purposes.
                    </p>
                    <p>
                        Like many businesses, we also collect information through cookies and similar
                        technologies.
                    </p>
                    <p>The information we collect includes:</p>
                    <ul>
                        <li>
                            <p>
                                <strong>Log and Usage Data.</strong> Log and usage data is service-related,
                                diagnostic, usage and performance information our servers automatically
                                collect when you access or use our Website and which we record in log files.
                                Depending on how you interact with us, this log data may include your IP
                                address, device information, browser type and settings and information about
                                your activity in the Website (such as the date/time stamps associated with
                                your usage, pages and files viewed, searches and other actions you take such
                                as which features you use), device event information (such as system activity,
                                error reports (sometimes called &apos;crash dumps&apos;) and hardware
                                settings).
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Device Data.</strong> We collect device data such as information about
                                your computer, phone, tablet or other device you use to access the Website.
                                Depending on the device used, this device data may include information such as
                                your IP address (or proxy server), device and application identification
                                numbers, location, browser type, hardware model Internet service provider
                                and/or mobile carrier, operating system and system configuration information.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Location Data.</strong> We collect location data such as information
                                about your device&apos;s location, which can be either precise or imprecise.
                                How much information we collect depends or the type and settings of the device
                                you use to access the Website. For example, we may use GPS and other
                                technologies to collect geolocation data that tells us your current location
                                (based on your IP address). You can opt out of allowing us to collect this
                                information either by refusing access to the information or by disabling your
                                Location setting on your device. Note however, it you choose to opt out, you
                                may not be able to use certain aspects of the Services.
                            </p>
                        </li>
                    </ul>
                </div>

                <div className={classes.section}>
                    <p className={classes.subtitle}>HOW DO WE USE YOUR INFORMATION?</p>
                    <p>
                        <i>
                            In Short: We process your information for purposes based on legitimate business
                            interests; the fulfilment of our contract with you, compliance with our legal
                            obligations, and/or your consent.
                        </i>
                    </p>

                    <p>
                        We use personal information collected via our Website for a variety of business
                        purposes described below. We process your personal information for these purposes in
                        reliance on our legitimate business interests, in order to enter into or perform a
                        contract with you, with your consent, and/or tor compliance with our legal
                        obligations. We indicate the specific processing grounds we rely on next to each
                        purpose listed below.
                    </p>

                    <p>We use the information we collect or receive:</p>

                    <ul>
                        <li>
                            <p>
                                <strong>Fulfil and manage your orders.</strong> We may use your information to
                                fulfil and manage your orders, payments, returns, and exchanges made through
                                the Website.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>To deliver and facilitate delivery of services to the user.</strong>{' '}
                                We may use your information to provide you with the requested service.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>To respond to user inquiries/offer support to users.</strong> We may
                                use your information to respond to your inquiries and solve any potential
                                issues you might nave with the use of our Services.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>To send you marketing and promotional communications.</strong> We
                                and/or our third-party marketing partners may use the personal information you
                                send to us for our marketing.
                            </p>
                        </li>
                    </ul>
                </div>
                <div className={classes.section}>
                    <p className={classes.subtitle}>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</p>

                    <p>
                        <i>
                            In Short: We only share information with your consent, to comply with laws, to
                            provide you with services, to protect your rights, or to fulfill business
                            obligations.
                        </i>
                    </p>

                    <p>We may process or share your data that we hold based on the following legal basis:</p>

                    <ul>
                        <li>
                            <p>
                                <strong>Consent:</strong> We may process your data if you have given us
                                specific consent to use your personal information for a specific purpose.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Legitimate Interests:</strong> We may process your data when it is
                                reasonably necessary to achieve our legitimate business interests.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Performance of a Contract:</strong> Where we have entered into a
                                contract with you, we may process your personal information to fulfill the
                                terms of our contract.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Legal Obligations:</strong> We may disclose your information where we
                                are legally required to do so in order to comply with applicable law,
                                governmental requests, a judicial proceeding, court order, or legal process,
                                such as in response to a court order or a subpoena (including in response to
                                public authorities to meet national security or law enforcement requirements).
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Vital Interests:</strong> We may disclose your information where we
                                believe it is necessary to investigate, prevent, or take action regarding
                                potential violations of our policies, suspected fraud, situations involving
                                potential threats to the safety of any person and illegal activities, or as
                                evidence in litigation in which we are involved.
                            </p>
                        </li>
                    </ul>

                    <p>
                        More specifically, we may need to process your data or share your personal information
                        in the following situations:
                    </p>

                    <ul>
                        <li>
                            <p>
                                <strong>Business Transfers:</strong> We may share or transfer your information
                                in connection with, or during negotiations of, any merger, sale of company
                                assets, financing, or acquisition of all or a portion of our business to
                                another company.
                            </p>
                        </li>
                    </ul>
                </div>

                <div className={classes.section}>
                    <p className={classes.subtitle}>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>

                    <p>
                        <i>
                            In Short: We may use cookies and other tracking technologies to collect and store
                            your information.
                        </i>
                    </p>

                    <p>
                        We may use cookies and similar tracking technologies (like web beacons and pixels) to
                        access or store information. Specific information about how we use such technologies
                        and how you can refuse certain cookies is set out in our Cookie Notice.
                    </p>
                </div>

                <div className={classes.section}>
                    <p className={classes.subtitle}>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</p>

                    <p>
                        <i>
                            In Short: We may transfer, store, and process your information in countries other
                            than your own.
                        </i>
                    </p>

                    <p>
                        If you are accessing our Website from outside, please be aware that your information
                        may be transferred to, stored, and processed by us in our facilities and by those
                        third parties with whom we may share your personal information (see &quot;WILL YOUR
                        INFORMATION BE SHARED WITH ANYONE?&quot; above), in and other countries.
                    </p>

                    <p>
                        If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then
                        these countries may not necessarily have data protection laws or other similar laws as
                        comprehensive as those in your country. We will however take all necessary measures to
                        protect your personal information in accordance with this privacy notice and
                        applicable law.
                    </p>
                </div>

                <div className={classes.section}>
                    <p className={classes.subtitle}>HOW LONG DO WE KEEP YOUR INFORMATION?</p>

                    <p>
                        <i>
                            In Short: We keep your information for as long as necessary to fulfil the purposes
                            outlined in this privacy notice unless otherwise required by law.
                        </i>
                    </p>

                    <p>
                        We will only keep your personal information for as long as it is necessary for the
                        purposes set out in this privacy notice, unless a longer retention period is required
                        or permitted by law (such as tax, accounting or other legal requirements). No purpose
                        in this notice will require us keeping your personal information for longer than 1
                        year.
                    </p>

                    <p>
                        When we have no ongoing legitimate business need to process your personal information,
                        we will either delete or anonymize such information, or, if this is not possible (for
                        example, because your personal information has been stored in backup archives), then
                        we will securely store your personal information and isolate it from any further
                        processing until deletion is possible.
                    </p>
                </div>

                <div className={classes.section}>
                    <p className={classes.subtitle}>HOW DO WE KEEP YOUR INFORMATION SAFE?</p>

                    <p>
                        <i>
                            In Short: We aim to protect your personal information through a system of
                            organizational and technical security measures.
                        </i>
                    </p>

                    <p>
                        We have implemented appropriate technical and organizational security measures
                        designed to protect the security of any personal information we process. However,
                        despite our safeguards and efforts to secure your information, no electronic
                        transmission over the Internet or information storage technology can be guaranteed to
                        be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or
                        other unauthorized third parties will not be able to defeat our security, and
                        improperly collect, access, steal, or modify your information. Although we will do our
                        best to protect your personal information, transmission of personal information to and
                        from our Website is at your own risk. You should only access the Website within a
                        secure environment.
                    </p>
                </div>

                <div className={classes.section}>
                    <p className={classes.subtitle}>DO WE COLLECT INFORMATION FROM MINORS?</p>

                    <p>
                        <i>
                            In Short: We do not knowingly collect data from or market to children under 18
                            years of age.
                        </i>
                    </p>

                    <p>
                        We do not knowingly solicit data from or market to children under 18 years of age. By
                        using the Website, you represent that you are at least 18 or that you are the parent
                        or guardian of such a minor and consent to such minor dependent&apos;s use of the
                        Website. If we learn that personal information from users less than 18 years of age
                        has been collected, we will deactivate the account and take reasonable measures to
                        promptly delete such data from our records. If you become aware of any data we may
                        have collected from children under age 18, please contact us at{' '}
                        <a target="_blank" rel="noreferrer" href="mailto:contact@justdormantaccounts.co.uk">
                            contact@justdormantaccounts.co.uk
                        </a>
                        .
                    </p>
                </div>

                <div className={classes.section}>
                    <p className={classes.subtitle}>WHAT ARE YOUR PRIVACY RIGHTS?</p>

                    <p>
                        <i>In Short: You may review, change, or terminate your account at any time.</i>
                    </p>

                    <p>
                        If you are a resident in the EEA or UK and you believe we are unlawfully processing
                        your personal information, you also have the right to complain to your local data
                        protection supervisory authority. You can find their contact details here:{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                        >
                            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                        </a>
                        .
                    </p>

                    <p>
                        If you are a resident in Switzerland, the contact details for the data protection
                        authorities are available here:{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                        >
                            https://www.edoeb.admin.ch/edoeb/en/home.html
                        </a>
                        . Cookies and similar technologies: Most Web browsers are set to accept cookies by
                        default. If you prefer, you can usually choose to set your browser to remove cookies
                        and to reject cookies. If you choose to remove cookies or reject cookies, this could
                        affect certain features or services of our Website. To opt-out of interest-based
                        advertising by advertisers on our Website visit{' '}
                        <a target="_blank" rel="noreferrer" href="http://www.aboutads.info/choices/">
                            http://www.aboutads.info/choices/
                        </a>
                        .
                    </p>
                </div>

                <div className={classes.section}>
                    <p className={classes.subtitle}>CONTROLS FOR DO-NOT-TRACK FEATURES</p>

                    <p>
                        Most web browsers and some mobile operating systems and mobile applications include a
                        Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your
                        privacy preference not to have data about your online browsing activities monitored
                        and collected. At this stage no uniform technology standard for recognizing and
                        implementing DNT signals has been finalized. As such, we do not currently respond to
                        DNT browser signals or any other mechanism that automatically communicates your choice
                        not to be tracked online. If a standard for online tracking is adopted that we must
                        follow in the future, we will inform you about that practice in a revised version of
                        this privacy notice.
                    </p>
                </div>

                <div className={classes.section}>
                    <p className={classes.subtitle}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>

                    <p>
                        If you have questions or comments about this notice, you may email us at{' '}
                        <a target="_blank" rel="noreferrer" href="mailto:contact@justdormantaccounts.co.uk">
                            contact@justdormantaccounts.co.uk
                        </a>{' '}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
