import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    app: {
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            display: 'flex',
            flexDirection: 'column',
        },
    },
});
