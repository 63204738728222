import React, { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Typography } from '@material-ui/core';

import Company from '../../../models/Company';
import CompaniesFormikAutocomplete from '../CompaniesFormikAutocomplete';
import { searchCompany } from '../../../services/SearchService';
import { useStyles } from './styles';
import { SearchForm } from '../config';
import SearchSubmitDialog from '../SearchSubmitDialog';
import InfoDialog from '../../../components/InfoDialog';
import useDebouncedSearch from '../../../config/hooks/useDebouncedSearch';

const SearchCompanyForm: FC = () => {
    const classes = useStyles();
    const [companies, setCompanies] = useState<Company[]>([]);

    const { handleSubmit, values, setFieldValue } = useFormikContext<SearchForm>();
    const [loading, setLoading] = useState<boolean>(false);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

    const searchValue = useDebouncedSearch(values.search);

    useEffect(() => {
        setLoading(true);
    }, [values.search]);

    useEffect(() => {
        if (searchValue.length) {
            searchCompany(searchValue, 25).then(response => {
                setCompanies(response.items);
                setLoading(false);
            });
        } else {
            setCompanies([]);
            setLoading(false);
        }
    }, [searchValue]);

    const autocompleteOnChange = (event: React.ChangeEvent<{ value: unknown }>, option: Company) => {
        const titleLastPart = option?.title?.split(' ').pop()?.toLowerCase();

        if (titleLastPart && !titleLastPart?.includes('ltd') && !titleLastPart?.includes('limited')) {
            setShowErrorDialog(true);
            setFieldValue('pickedCompany', null);
        } else setFieldValue('pickedCompany', option);
    };

    return (
        <>
            <form onSubmit={handleSubmit} className={classes.customInputContainer}>
                <CompaniesFormikAutocomplete
                    name="pickedCompany"
                    placeholder="Type your company name or registration number..."
                    inputFormikName="search"
                    options={companies}
                    loadingOptions={loading}
                    onChange={autocompleteOnChange}
                />
                <SearchSubmitDialog />
            </form>

            <InfoDialog open={showErrorDialog} setOpen={setShowErrorDialog} error>
                <Typography className={classes.text}>
                    This service is only for Companies limited by shares or by guarantee.
                </Typography>
            </InfoDialog>
        </>
    );
};

export default SearchCompanyForm;
