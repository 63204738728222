import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../config/theme';

export const useStyles = makeStyles({
    container: { maxWidth: 610 },
    pageTitle: {
        marginBottom: 18,
        marginTop: 10,
    },
    list: {
        listStyle: 'none',
        padding: 0,
        marginBottom: 20,
    },
    bullet: {
        marginRight: 8,
        fontSize: 22,
        marginTop: -2,
        marginBottom: 0,
    },
    listItem: {
        padding: 4,
        paddingLeft: 0,
        fontSize: 16,
        lineHeight: '140%',
        color: '#001733',
        display: 'flex',
        flexDirection: 'row',
    },
    listItemText: {
        marginTop: 0,
        marginBottom: 0,
    },
    confirmText: {
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '145%',
        color: '#001733',
        margin: 0,
    },
    button: {
        width: 108,
        height: 42,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
        borderRadius: 5,
        marginRight: 10,
        marginTop: 30,
    },
    buttonLabel: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '140%',
        color: 'rgba(0, 23, 51, 0.5)',
        textTransform: 'none',
    },
    activeButton: {
        backgroundColor: '#006FF1',
    },
    activeButtonLabel: {
        color: '#fff',
    },
    shareCapital: {
        width: '100%',
        margin: '60px 0 0 0',
    },
    shareCapitalInput: {
        backgroundColor: '#FFFFFF',
        borderRadius: '5px 0px 0px 5px',
        height: 64,
        paddingLeft: 12,
        flex: 1,
        minHeight: 64,
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
});
