import { FC, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import DayJsUtils from '@date-io/dayjs';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';

import { theme } from '../../config/theme';

import AxiosProvider from '../AxiosProvider';
import CompanyContext from '../CompanyContext';
import Company, { initialCompanyValue } from '../../models/Company';

const AppProvider: FC = ({ children }) => {
    const [company, setCompany] = useState<Company>(initialCompanyValue);

    return (
        <AxiosProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <CompanyContext.Provider value={{ company, setCompany }}>
                        <MuiPickersUtilsProvider utils={DayJsUtils}>{children}</MuiPickersUtilsProvider>
                    </CompanyContext.Provider>
                </SnackbarProvider>
            </ThemeProvider>
        </AxiosProvider>
    );
};

export default AppProvider;
