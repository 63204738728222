import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    customInputContainer: {
        display: 'grid',
        alignItems: 'center',
        flex: 1,
        maxWidth: 640,
        position: 'relative',
        gridTemplateColumns: 'auto',
        gridGap: 32,
        // margin: '0 16px',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '1fr 140px',
            gridGap: 0,
        },
    },
    text: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        textAlign: 'center',
        lineHeight: '140%',
        color: '#001733',
    },
}));
