import { FC, useState } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { useStyles } from './styles';
import { routes } from '../../config/routing';
import PublicRoute from '../PublicRoute';
import Header from '../../parts/Header';
import StepperAndBackButton from '../../parts/StepperAndBackButton';
import { STEPPER_CONFIG } from '../../config/constants';
import AppProvider from '../AppProvider';
import MenuContext from '../MenuContext';

const App: FC = () => {
    const classes = useStyles();

    const [openTooltips, setOpenTooltips] = useState<boolean[]>([false, false, false, false, false]);

    const openTooltip = (key: number) => {
        setOpenTooltips([
            ...openTooltips.map((tooltip: boolean, index: number) => key === index && !openTooltips[key]),
        ]);
    };

    const handleClickAway = (key: number) => {
        if (openTooltips[key]) setOpenTooltips([false, false, false, false, false]);
    };

    const closeAllTooltips = () => {
        setOpenTooltips([false, false, false, false, false]);
    };

    return (
        <Router>
            <AppProvider>
                <MenuContext.Provider
                    value={{ openTooltips, openTooltip, handleClickAway, closeAllTooltips }}
                >
                    <div className={classes.app}>
                        <Header />
                        <main className={classes.main}>
                            <PublicRoute
                                component={StepperAndBackButton}
                                path={STEPPER_CONFIG.map(({ path }) => path)}
                            />
                            <Switch>
                                {routes.map(route => (
                                    <PublicRoute
                                        key={route.path}
                                        path={route.path}
                                        exact={route.exact}
                                        component={route.component}
                                    />
                                ))}
                            </Switch>
                        </main>
                    </div>
                </MenuContext.Provider>
            </AppProvider>
        </Router>
    );
};

export default App;
