import React, { FC } from 'react';
import { useField } from 'formik';
import clsx from 'clsx';

import { FormikTextFieldProps } from '../FormikTextField/types';
import FormikTextField from '../FormikTextField';

import { useStyles } from './styles';
import ErrorIcon from '../../assets/images/error-icon.svg';
import CompletedIcon from '../../assets/images/completed-icon.svg';

const CustomFormikTextField: FC<FormikTextFieldProps> = ({ name, InputProps, ...otherProps }) => {
    const classes = useStyles();
    const { error, touched } = useField(name)[1];

    return (
        <div className={classes.container}>
            <FormikTextField
                InputProps={{
                    classes: {
                        error: classes.error,
                        input: classes.input,
                        root: classes.root,
                        focused: classes.focused,
                        // @ts-ignore
                        notchedOutline: clsx(classes.notchedOutline, {
                            [classes.completed]: touched && !error,
                        }),
                    },
                    ...InputProps,
                }}
                name={name}
                margin="none"
                variant="outlined"
                classes={{ root: classes.rootTextField }}
                {...otherProps}
            />
            {!!error && touched && (
                <img src={ErrorIcon} alt="Error" width="3" height="13" className={classes.errorIcon} />
            )}
            {touched && !error && (
                <img
                    src={CompletedIcon}
                    alt="Completed"
                    width="12"
                    height="8"
                    className={classes.statusIcon}
                />
            )}
        </div>
    );
};

export default CustomFormikTextField;
