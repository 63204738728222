import React, { FC } from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';

import InputLabel from '@material-ui/core/InputLabel';

import { FormikTextFieldProps } from './types';

const FormikTextField: FC<FormikTextFieldProps> = ({
    disableLabel,
    id,
    label,
    name,
    component = TextField,
    InputLabelProps,
    ...otherProps
}) => {
    const renderContent = () => (
        <Field
            component={component}
            fullWidth
            autoComplete="off"
            variant="outlined"
            id={id || name}
            name={name}
            {...otherProps}
        />
    );

    if (disableLabel) {
        return renderContent();
    }

    return (
        <div>
            <InputLabel htmlFor={id || name} {...InputLabelProps}>
                {label || name}
            </InputLabel>
            <Field
                component={component}
                fullWidth
                autoComplete="off"
                variant="outlined"
                id={id || name}
                name={name}
                {...otherProps}
            />
        </div>
    );
};

export default FormikTextField;
