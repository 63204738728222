import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    container: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.05)',
        borderRadius: 5,
        width: 385,
        padding: 32,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 28,
        lineHeight: '140%',
        color: '#001733',
        paddingBottom: 10,
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    },
    regularText: {
        marginTop: 22,
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '145%',
        color: '#001733',
    },
    blueText: {
        marginTop: 5,
        color: '#006FF1',
    },
});
