import { FC, useState } from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import { Button, ClickAwayListener, useMediaQuery } from '@material-ui/core';
import { useStyles } from './styles';

interface Props extends TooltipProps {
    large?: boolean;
    onClose?: () => void;
}

const CustomTooltip: FC<Props> = ({ children, title, large, onClose, ...otherProps }) => {
    const classes = useStyles();

    const isMobile = useMediaQuery(`(max-width: 900px)`);

    const [open, setOpen] = useState<boolean>(otherProps.open || false);

    const renderTooltip = () => (
        <Tooltip
            title={title}
            arrow={otherProps.arrow}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                if (!onClose && !isMobile) {
                    setOpen(false);
                }
            }}
            classes={{
                popper: clsx({ [classes.largeTooltip]: large }),
                tooltip: clsx(classes.root, { [classes.largeTooltip]: large }),
                arrow: classes.tooltipArrow,
            }}
            {...otherProps}
        >
            {children}
        </Tooltip>
    );

    return (
        <ClickAwayListener
            onClickAway={() => {
                if (open) {
                    setOpen(false);
                    if (onClose) onClose();
                }
            }}
        >
            {isMobile ? (
                <Button
                    variant="text"
                    disableElevation
                    disableRipple
                    disableTouchRipple
                    disableFocusRipple
                    onClick={() => {
                        setOpen(true);
                    }}
                    className={classes.button}
                >
                    {renderTooltip()}
                </Button>
            ) : (
                renderTooltip()
            )}
        </ClickAwayListener>
    );
};

CustomTooltip.defaultProps = {
    large: false,
    onClose: undefined,
};

export default CustomTooltip;
