import React, { FC } from 'react';

import { FormikTextFieldProps } from '../../../components/FormikTextField/types';
import FormikTextField from '../../../components/FormikTextField';

import { useStyles } from './styles';

const AuthFormikTextField: FC<FormikTextFieldProps> = ({ InputProps, ...otherProps }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <FormikTextField
                InputProps={{
                    disableUnderline: true,
                    classes: { root: classes.rootTextField },
                    ...InputProps,
                }}
                margin="none"
                {...otherProps}
            />
        </div>
    );
};

export default AuthFormikTextField;
