import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { NavLink, useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import InfoDialog from '../../../components/InfoDialog';
import { useStyles as useDialogStyles } from '../../../components/InfoDialog/styles';
import DormancyDescription from '../DormancyDescription';
import { ROUTES } from '../../../config/constants';
import CompanyContext from '../../../components/CompanyContext';
import { generateNewDocument } from '../../../services/FinancialDataService';
import CompanyFinancialDataModel from '../../../models/CompanyFinancialData.model';

interface Props {
    canSubmit: boolean | undefined;
    noData: boolean | undefined;
    error: boolean | undefined;
    date: string | undefined;
    financialData?: CompanyFinancialDataModel;
}

const FinancialDataConfirmation: FC<Props> = ({ canSubmit, noData, error, date, financialData }) => {
    const classes = useStyles();
    const dialogClasses = useDialogStyles();
    const history = useHistory();
    const { company, setCompany } = useContext(CompanyContext);

    const [declarationDialogOpen, setDeclarationDialogOpen] = useState<boolean>(false);
    const [confirmedDeclaration, setConfirmedDeclaration] = useState<boolean | undefined>(undefined);
    const [dormancyDialogOpen, setDormancyDialogOpen] = useState<boolean>(false);
    const [confirmedDormancy, setConfirmedDormancy] = useState<boolean | undefined>(undefined);

    const [alreadySubmittedDialogOpen, setAlreadySubmittedDialogOpen] = useState<boolean>(false);
    const [noDataDialog, setNoDataDialog] = useState<boolean>(false);
    const [errorDialog, setErrorDialog] = useState<boolean>(false);
    const [showInput, setShowInput] = useState<boolean>(false);
    const [shareCapital, setShareCapital] = useState<number>(1);

    useEffect(() => {
        if (confirmedDormancy && confirmedDeclaration && canSubmit && !noData) {
            history.push('/authentication');
        } else if (confirmedDeclaration && confirmedDormancy && error) {
            setErrorDialog(true);
        } else if (confirmedDormancy && confirmedDeclaration && !canSubmit) {
            setAlreadySubmittedDialogOpen(true);
        } else if (!confirmedDormancy && !confirmedDeclaration && noData) {
            setNoDataDialog(true);
        }
    }, [history, confirmedDormancy, confirmedDeclaration, canSubmit, error, noData, company, setCompany]);

    const renderDeclarationConfirmationText = () => (
        <>
            <Typography variant="h1" className={classes.pageTitle}>
                Statement Confirmation
            </Typography>
            <ul className={classes.list}>
                <li className={classes.listItem}>
                    <span className={classes.bullet}>•</span>
                    <span>
                        For the year ending {!!date && dayjs(date).format('DD MMMM YYYY')} the company was
                        entitled to exemption from audit under section 480 of the Companies Act 2006 relating
                        to dormant companies.
                    </span>
                </li>
                <li className={classes.listItem}>
                    <span className={classes.bullet}>•</span>
                    <span>
                        The members have not required the company to obtain an audit in accordance with
                        section 476 of the Companies Act 2006.
                    </span>
                </li>
                <li className={classes.listItem}>
                    <span className={classes.bullet}>•</span>
                    <span>
                        The directors acknowledge their responsibilities for complying with the requirements
                        of the Act with respect to accounting records and the preparation of accounts.
                    </span>
                </li>
                <li className={classes.listItem}>
                    <span className={classes.bullet}>•</span>
                    <span>
                        These accounts have been prepared in accordance with the provision applicable to
                        companies subject to small companies&apos; regime.
                    </span>
                </li>
            </ul>

            <p className={classes.confirmText}>
                Do you, as the director of the company, confirm that the balance sheet displayed is accurate
                and complete to the best of your knowledge?
            </p>
        </>
    );

    const renderButtons = (
        confirmed: boolean | undefined,
        setConfirmation: (value: boolean) => void,
        setDialogState: (value: boolean) => void,
    ) => (
        <>
            <Button
                color="primary"
                classes={{
                    root: clsx(classes.button, { [classes.activeButton]: confirmed }),
                    label: clsx(classes.buttonLabel, { [classes.activeButtonLabel]: confirmed }),
                }}
                onClick={() => setConfirmation(true)}
            >
                Yes
            </Button>
            <Button
                color="primary"
                classes={{
                    root: clsx(classes.button, { [classes.activeButton]: confirmed === false }),
                    label: clsx(classes.buttonLabel, { [classes.activeButtonLabel]: confirmed === false }),
                }}
                onClick={() => {
                    setConfirmation(false);
                    setDialogState(true);
                }}
            >
                No
            </Button>
        </>
    );

    const renderDialogs = () => (
        <>
            <InfoDialog open={declarationDialogOpen} setOpen={setDeclarationDialogOpen}>
                <Typography className={dialogClasses.text}>
                    Sorry, you will need to confirm the information and your responsibilities as director of
                    your company.
                </Typography>
            </InfoDialog>

            <InfoDialog open={dormancyDialogOpen} setOpen={setDormancyDialogOpen}>
                <Typography className={dialogClasses.text}>
                    {`Sorry, this service is only suitable for `}
                    <span className={dialogClasses.textBlue}>dormant</span> company accounts.
                </Typography>
            </InfoDialog>

            <InfoDialog open={alreadySubmittedDialogOpen} setOpen={setAlreadySubmittedDialogOpen}>
                <Typography className={dialogClasses.text}>
                    The latest accounts have already been filed and cannot be filed again or the first year
                    has not yet passed. You will need to wait until the next accounting year has passed in
                    order to file accounts.
                </Typography>
            </InfoDialog>

            <InfoDialog open={errorDialog} setOpen={setErrorDialog} error>
                <Typography className={dialogClasses.text}>
                    Sorry, there is an issue with your data that does not allow your accounts to be filed
                    using this service. Please use an alternative service or{' '}
                    <NavLink to={ROUTES.PUBLIC.CONTACT}>get in touch</NavLink> with us if you have any
                    questions.
                </Typography>
            </InfoDialog>

            <InfoDialog open={noDataDialog} setOpen={setNoDataDialog} noButton>
                {!showInput && (
                    <>
                        <Typography className={dialogClasses.text}>
                            Is this your company&apos;s first year?
                        </Typography>

                        <div className={dialogClasses.buttonsWrapper}>
                            <Button
                                autoFocus
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setNoDataDialog(false);
                                    setErrorDialog(true);
                                }}
                                classes={{ root: dialogClasses.buttonRoot, label: dialogClasses.buttonLabel }}
                            >
                                No
                            </Button>

                            <Button
                                autoFocus
                                variant="contained"
                                color="primary"
                                onClick={() => setShowInput(true)}
                                classes={{ root: dialogClasses.buttonRoot, label: dialogClasses.buttonLabel }}
                            >
                                Yes
                            </Button>
                        </div>
                    </>
                )}

                {showInput && (
                    <>
                        <Typography className={dialogClasses.text}>
                            What is the company&apos;s Share Capital? This is the number of shares issued on
                            incorporation multiplied by the value of each share (often this is £1 per share).
                        </Typography>

                        <TextField
                            variant="outlined"
                            className={classes.shareCapital}
                            InputProps={{ className: classes.shareCapitalInput }}
                            label="Share Capital"
                            value={shareCapital.toString()}
                            onChange={e => setShareCapital(parseInt(e.target.value, 10) || 0)}
                        />

                        <Button
                            autoFocus
                            variant="contained"
                            color="primary"
                            onClick={async () => {
                                if (financialData) {
                                    const newFinancialData: CompanyFinancialDataModel = {
                                        ...financialData,
                                        companyName: company.title,
                                        companyNumber: company.company_number,
                                        currentYear: {
                                            ...financialData.currentYear,
                                            currentAssets: {
                                                value: shareCapital,
                                                fields: {},
                                            },
                                            netAssets: {
                                                value: shareCapital,
                                                fields: {},
                                            },
                                            capitalAndReserves: {
                                                value: shareCapital,
                                                fields: {},
                                            },
                                        },
                                    };
                                    newFinancialData.newDocument = await generateNewDocument(
                                        newFinancialData,
                                    );

                                    setCompany({
                                        ...company,
                                        financialData: newFinancialData,
                                        financialDataLoaded: true,
                                    });

                                    setShowInput(false);
                                    setNoDataDialog(false);
                                    setErrorDialog(false);
                                }
                            }}
                            classes={{ root: dialogClasses.buttonRoot, label: dialogClasses.buttonLabel }}
                        >
                            Continue
                        </Button>
                    </>
                )}
            </InfoDialog>
        </>
    );

    return (
        <div className={classes.container}>
            {renderDeclarationConfirmationText()}
            {renderButtons(confirmedDeclaration, setConfirmedDeclaration, setDeclarationDialogOpen)}

            <DormancyDescription />
            {renderButtons(confirmedDormancy, setConfirmedDormancy, setDormancyDialogOpen)}

            {renderDialogs()}
        </div>
    );
};

FinancialDataConfirmation.defaultProps = {
    financialData: undefined,
};

export default FinancialDataConfirmation;
