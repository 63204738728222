import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        color: 'black',
        padding: '20px 22px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '18.2px',
        boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.05)',
        borderRadius: 5,
        maxWidth: 400,
    },
    tooltipArrow: {
        '&::before': {
            backgroundColor: 'white',
        },
    },
    largeTooltip: {
        [theme.breakpoints.up(960)]: {
            maxWidth: '100% !important',
        },
    },
    button: {
        minWidth: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));
