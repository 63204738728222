import React, { FC } from 'react';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import clsx from 'clsx';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useStyles } from './styles';

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    action?: () => void;
    error?: boolean;
    noButton?: boolean;
}

const InfoDialog: FC<Props> = ({ open, setOpen, action, children, error, noButton }) => {
    const classes = useStyles();

    return (
        <Dialog onClose={() => setOpen(false)} open={open} classes={{ paper: classes.container }}>
            <DialogContent className={classes.dialogContent}>
                {!error ? (
                    <InfoOutlinedIcon className={classes.icon} />
                ) : (
                    <HighlightOffIcon className={clsx(classes.icon, { [classes.error]: error })} />
                )}

                {children}

                {!noButton && (
                    <Button
                        autoFocus
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setOpen(false);
                            if (action) action();
                        }}
                        classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
                    >
                        OK
                    </Button>
                )}
            </DialogContent>
        </Dialog>
    );
};

InfoDialog.defaultProps = {
    action: undefined,
    error: undefined,
    noButton: undefined,
};

export default InfoDialog;
