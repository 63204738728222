import { FC } from 'react';
import { StepIconProps } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';

const StepIcon: FC<StepIconProps> = props => {
    const classes = useStyles();
    const { active, completed } = props;

    return (
        <div className={classes.root}>
            <div
                className={clsx(classes.circle, {
                    [classes.completed]: completed,
                    [classes.active]: active,
                    [classes.inactive]: !completed && !active,
                })}
            />
        </div>
    );
};
export default StepIcon;
