import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    popper: {
        top: '60px !important',
        left: '0px !important',
        right: '0px !important',
        width: 'auto !important',
        transform: 'none !important',
        height: 228,
        marginTop: 12,
        boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.05)',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    popperPaper: {
        height: 228,
    },
    popperNoOptions: {
        boxShadow: 'none',
        height: 50,
    },
    list: {
        height: 228,
        padding: '4px 0',
        '&::-webkit-scrollbar-track': {
            // '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: 10,
            backgroundColor: '#FFFFFF',
        },
        '&::-webkit-scrollbar': {
            width: 8,
            backgroundColor: '8px solid rgba(0, 0, 0, 0.2)',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            // '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            backgroundColor: '#CCCCCC',
        },
    },
    option: {
        height: 57,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 18,
        lineHeight: '140%',
        color: '#001733',
        '&[data-focus="true"]': {
            backgroundColor: '#F1F6FF',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        },
    },
    popperHidden: {
        display: 'none',
    },
    paperNoOptions: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
    },
    noOptions: {
        fontWeight: 600,
        fontSize: 15,
        lineHeight: '135%',
        color: '#E44447',
        paddingLeft: 0,
        paddingTop: 8,
    },
    searchInput: {
        backgroundColor: '#FFFFFF',
        borderRadius: '5px 0px 0px 5px',
        height: 64,
        paddingLeft: 22,
        flex: 1,
        maxWidth: 510,
        boxShadow: '0 0 3px 5px rgba(0,0,0,0.01)',
    },
    input: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 18,
        lineHeight: '140%',
        '&::placeholder': {
            fontSize: 18,
            [theme.breakpoints.down('sm')]: {
                fontSize: 15,
            },
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        },
    },
    textField: {
        marginTop: 14,
    },
}));
