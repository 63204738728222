import { FC, useContext } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import CompanyContext from '../../../components/CompanyContext';
import { useStyles } from './styles';

const PaymentSummary: FC = () => {
    const classes = useStyles();
    const { company } = useContext(CompanyContext);

    const renderListItem = (header: string, value: string) => (
        <>
            <Typography className={classes.regularText}>{header}:</Typography>
            <Typography className={clsx(classes.regularText, classes.blueText)}>{value}</Typography>
        </>
    );

    return (
        <div className={classes.container}>
            <Typography className={classes.title}>Summary</Typography>

            {renderListItem('Company number', company.company_number)}
            {renderListItem('Company name', company.title)}
            {renderListItem('File date', dayjs().format('DD/MM/YYYY'))}
            {renderListItem('Total amount paid', company.payment.amount)}
            {renderListItem(
                'Payment method',
                `${company.payment.cardType.toUpperCase()} ending in ${company.payment.cardEnding}`,
            )}
        </div>
    );
};

export default PaymentSummary;
