import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    companyName: {
        fontWeight: 700,
        fontSize: 18,
        lineHeight: '140%',
        textAlign: 'center',
    },
    balanceSheetTitle: {
        fontWeight: 600,
        display: 'block',
    },
    sheetContainer: {
        backgroundColor: 'white',
        boxShadow: '0px 0px 10px 10px rgba(0,0,0,0.02)',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 5,
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            minWidth: 450,
        },
        [theme.breakpoints.down('sm')]: {
            width: '98%',
        },
    },
    sheetHeaderBlue: {
        backgroundColor: '#F5F9FF',
    },
    sheetHeaderText: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '140%',
        letterSpacing: '0.01em',
        flexBasis: '50%',
    },
    sheetHeaderTextBlue: {
        color: '#006FF1',
    },
    sheetHeaderTextLarger: {
        fontSize: 18,
        fontWeight: 700,
    },

    tableCell: {
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: 0,
        lineHeight: '140%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: 25,
            paddingRight: 25,
            fontSize: 16,
            height: 46,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 5,
            paddingRight: 5,
            fontSize: 14,
        },
    },
    tableCellBolder: {
        fontWeight: 600,
    },
    tableCellHeader: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '140%',
        letterSpacing: '0.01em',
    },
    tableCellCompact: {
        height: 30,
        paddingTop: 0,
        paddingBottom: 0,
    },
    tableRowSpaced: {
        paddingTop: 25,
    },
    tableRowSpacedLess: {
        paddingTop: 15,
    },
    tableRowSpacedBottom: {
        paddingBottom: 25,
    },
}));
