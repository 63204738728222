import { makeStyles } from '@material-ui/core';
import { theme } from '../../config/theme';

export const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            flexGrow: 1,
        },
    },
    pageTitle: {
        marginBottom: 30,
        [theme.breakpoints.down('sm')]: {
            marginTop: 25,
            marginBottom: 10,
        },
    },
    subtitle: {
        maxWidth: 450,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16,
        paddingLeft: 20,
        paddingRight: 20,
    },
    form: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: 32,
        maxWidth: 486,
        padding: 16,
        width: '100%',
        margin: '0 auto',
    },
    inputLabel: {
        color: '#006FF1',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '19.6px',
        fontSpacing: '2%',
        marginBottom: 6,
    },
    button: {
        borderRadius: 5,
        height: 64,
        boxShadow: 'none',
        width: '100%',
        backgroundColor: '#006FF1',
        marginTop: 20,
    },
    label: {
        color: 'white',
        textTransform: 'none',
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '140%',
    },
    submitted: {
        marginTop: 20,
        marginBottom: 20,
        fontSize: 16,
        textAlign: 'center',
    },
    icon: {
        marginBottom: -6,
        marginRight: 10,
        color: 'green',
    },
});
