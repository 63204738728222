import { FC } from 'react';
import OpenInNew from '@material-ui/icons/OpenInNew';
import { useStyles } from '../styles';

const WhatINeed: FC = () => {
    const classes = useStyles();

    return (
        <>
            <p>You will need 2 things to use this service.</p>
            <p>
                <strong>Authentication Code:</strong> It is a 6 digit alphanumeric code needed to file online
                and is the equivalent of a company officer’s signature. You will have received an
                Authentication Code by letter from Companies House when you had set up your company. If you
                don’t know it or lost it, you can request a new one from the{' '}
                <a
                    href="https://www.gov.uk/guidance/company-authentication-codes-for-online-filing"
                    target="_blank"
                    rel="noreferrer"
                >
                    government website <OpenInNew className={classes.newLinkIcon} />
                </a>
                .
            </p>
            <p>
                <strong>Card Payment Details:</strong> To make payment at the last step. Ensure to pay with
                your personal card (and not with the dormant company’s business card if you already have one)
                so as not to create a{' '}
                <a
                    href="https://www.gov.uk/government/publications/life-of-a-company-annual-requirements/life-of-a-company-part-1-accounts#dormant-accounts"
                    target="_blank"
                    rel="noreferrer"
                >
                    ‘significant’ transaction <OpenInNew className={classes.newLinkIcon} />
                </a>{' '}
                and lose dormant status.
            </p>
        </>
    );
};

export default WhatINeed;
