// @ts-nocheck
import React, { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import clsx from 'clsx';
import { Typography, CircularProgress, Button, InputLabel, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './CardDetails.styles';
import { validationSchema, initialValues, CardDetailsForm } from './config';
import CustomFormikTextField from '../CustomFormikTextField';
import PayIcon from '../../assets/images/pay-icon.svg';
import FormikStripeElement from '../FormikStripeElement';
import CompanyContext from '../CompanyContext';
import { submitForm } from '../../services/FileSubmissionService';
import { createPaymentIntent, getCustomerId, PaymentIntent } from '../../services/CheckoutService';
import { ROUTES } from '../../config/constants';
import MenuContext from '../MenuContext';

const ContactDetails: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const stripe = useStripe();
    const elements = useElements();
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { company, setCompany } = useContext(CompanyContext);
    const isMobile = useMediaQuery(`(max-width: 900px)`);
    const { openTooltip } = useContext(MenuContext);

    const [numberComplete, setNumberComplete] = useState<boolean>(false);
    const [expiredComplete, setExpiredComplete] = useState<boolean>(false);
    const [cvcComplete, setCvcComplete] = useState<boolean>(false);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

    const CARD_OPTIONS = {
        style: {
            base: {
                fontFamily: theme.typography.fontFamily,
                fontSize: '18px',
                '::placeholder': {
                    color: 'rgba(0, 23, 51, 0.4)',
                    lineHeight: '18px',
                    letterSpacing: '0.1px',
                    fontWeight: 400,
                },
            },
        },
    };

    const sendBalanceSheetToCH = async (paymentIntent: PaymentIntent) => {
        const charge = paymentIntent.charges.data[0];

        const newCompanyObject = {
            ...company,
            payment: {
                transactionId: charge.id,
                cardEnding: charge.payment_method_details.card.last4,
                amount: `£${charge.amount.toString().replace(/\B(?=(\d{2})+(?!\d))/g, '.')}`,
                cardType: charge.payment_method_details.card.brand,
                email: charge.billing_details.email,
            },
        };

        if (await submitForm(newCompanyObject, paymentIntent.id, charge.billing_details.email)) {
            setCompany(newCompanyObject);
            history.push(ROUTES.PUBLIC.PAYMENT_CONFIRMATION);
        } else {
            enqueueSnackbar('Sending balance sheet to Companies House failed', { variant: 'error' });
        }
    };

    const processPaymentIntentResponse = async (paymentIntent: PaymentIntent) => {
        if (paymentIntent.id) {
            await sendBalanceSheetToCH(paymentIntent);
        } else if (paymentIntent.errorMessage) {
            enqueueSnackbar(`We could not process this transaction. ${paymentIntent.errorMessage}`, {
                variant: 'error',
            });
        } else {
            enqueueSnackbar('Payment failed. Please try again later', { variant: 'error' });
        }
    };

    const handleUpdateCardDetails = async (values: CardDetailsForm) => {
        if (!stripe || !elements) return;

        const { email, name } = values;

        setButtonDisabled(true);

        const customerId = await getCustomerId(email);
        if (!customerId) {
            enqueueSnackbar('Payment failed. Please try again later', { variant: 'error' });
            setButtonDisabled(false);
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name,
                email,
            },
        });

        if (error) {
            enqueueSnackbar('Payment failed. Please try again later', { variant: 'error' });
            setButtonDisabled(false);
            return;
        }

        const paymentIntent = await createPaymentIntent(customerId, email, paymentMethod.id);
        if (!paymentIntent) {
            enqueueSnackbar('Payment failed. Please try again later', { variant: 'error' });
            setButtonDisabled(false);
            return;
        }

        await processPaymentIntentResponse(paymentIntent);
        setButtonDisabled(false);
    };

    const openPolicies = () => {
        openTooltip(3);

        if (!isMobile) {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleUpdateCardDetails}
        >
            {({ handleSubmit, errors }) => {
                return (
                    <form onSubmit={handleSubmit} className={classes.form}>
                        <div>
                            <InputLabel htmlFor="email" className={classes.inputLabel}>
                                EMAIL
                            </InputLabel>
                            <CustomFormikTextField
                                disableLabel
                                id="email"
                                name="email"
                                placeholder="example@example.com"
                            />
                        </div>
                        <div>
                            <InputLabel htmlFor="name" className={classes.inputLabel}>
                                CARD HOLDER
                            </InputLabel>
                            <CustomFormikTextField
                                disableLabel
                                fullWidth
                                id="name"
                                name="name"
                                placeholder="Name on card"
                            />
                        </div>
                        <div>
                            <InputLabel className={classes.inputLabel}>CARD DETAILS</InputLabel>
                            <FormikStripeElement
                                options={CARD_OPTIONS}
                                name="cardNumber"
                                stripeElement={CardNumberElement}
                                className={clsx(classes.element, {
                                    [classes.elementComplete]: numberComplete,
                                    [classes.elementError]: !!errors.cardNumber,
                                })}
                                onChange={complete => setNumberComplete(complete)}
                            />
                        </div>

                        <div className={classes.cardDetailsWrapper}>
                            <div>
                                <InputLabel className={classes.inputLabel}>EXPIRY DATE</InputLabel>
                                <FormikStripeElement
                                    options={CARD_OPTIONS}
                                    name="cardExpiry"
                                    stripeElement={CardExpiryElement}
                                    className={clsx(classes.element, {
                                        [classes.elementComplete]: expiredComplete,
                                        [classes.elementError]: !!errors.cardExpiry,
                                    })}
                                    onChange={complete => setExpiredComplete(complete)}
                                />
                            </div>
                            <div>
                                <InputLabel className={classes.inputLabel}>CVC</InputLabel>
                                <FormikStripeElement
                                    options={CARD_OPTIONS}
                                    name="cvc"
                                    stripeElement={CardCvcElement}
                                    className={clsx(classes.element, {
                                        [classes.elementComplete]: cvcComplete,
                                        [classes.elementError]: !!errors.cvc,
                                    })}
                                    onChange={complete => setCvcComplete(complete)}
                                />
                            </div>
                        </div>

                        <div>
                            <Button
                                disabled={buttonDisabled}
                                type="submit"
                                variant="contained"
                                color="primary"
                                classes={{
                                    root: classes.startedButtonRoot,
                                    label: classes.startedButtonLabel,
                                    endIcon: classes.endIcon,
                                }}
                                endIcon={<img src={PayIcon} alt="Info" width="17.2" height="19.11" />}
                            >
                                {buttonDisabled ? <CircularProgress /> : `Pay £9.00`}
                            </Button>
                            <Typography className={classes.proceeding}>
                                By proceeding you agree to the
                                <Button
                                    variant="text"
                                    classes={{ root: classes.linkButton, label: classes.link }}
                                    onClick={openPolicies}
                                >
                                    {` terms and conditions `}
                                </Button>
                                {`and the `}
                                <Button
                                    variant="text"
                                    classes={{ root: classes.linkButton, label: classes.link }}
                                    onClick={openPolicies}
                                >
                                    privacy policy
                                </Button>
                                .
                            </Typography>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};

export default ContactDetails;
