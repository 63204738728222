import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
        color: '#001733',
    },
    header: {
        color: '#001733',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderBottom: '1px solid rgba(0, 23, 51, 0.2);',
        height: 90,
    },
    toolbar: {
        height: 90,
    },
    menuButton: {
        color: '#001733',
        marginRight: theme.spacing(2),
        textTransform: 'none',
        fontWeight: 600,
        fontSize: 16,
        letterSpacing: '0.01em',
        height: 88,
        backgroundColor: '#f0f5ff !important',
        [theme.breakpoints.up('md')]: {
            '& :hover': {
                borderBottom: '3px solid #006FF1',
                color: '#006FF1',
                marginBottom: -3,
            },
        },
    },
    buttonLabel: {
        height: 88,
    },
    title: {
        flexGrow: 1,
        fontSize: 18,
        marginLeft: 24,
    },
    titleLink: {
        textDecoration: 'none',
        color: 'rgb(0, 23, 51)',
    },
    newLinkIcon: {
        fontSize: 12,
        marginLeft: -1,
        marginBottom: -2,
    },
    dormantButton: {
        color: 'rgb(85, 26, 139)',
        fontSize: 14,
        height: 14,
        textTransform: 'none',
        padding: 0,
        margin: 0,
        fontWeight: 'bold',
        marginTop: -4,
        marginLeft: -3,
        marginRight: -3,
    },
}));
