import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 80,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
        },
    },
    form: {
        display: 'grid',
        width: '100%',
        maxWidth: 590,
        gridTemplateColumns: 'auto',
        gridGap: 32,
        padding: '0 16px',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '1fr 140px',
            gridGap: 0,
            textAlign: 'center',
        },
    },
    pageTitle: {
        marginRight: 12,
    },
    infoIcon: {
        marginTop: 2,
    },
    startedButtonRoot: {
        [theme.breakpoints.up('md')]: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
        height: 64,
        boxShadow: 'none',
        width: 140,
        backgroundColor: '#006FF1',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '95%',
    },
    startedButtonLabel: {
        color: 'white',
        textTransform: 'none',
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '140%',
    },
    description: {
        marginTop: 18,
        marginBottom: 70,
        fontWeight: 600,
        size: 18,
        lineHeight: '26.1px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 30,
        },
    },
    linkIcon: {
        fontSize: 13,
        marginBottom: -3,
    },
}));
