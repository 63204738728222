import axios from 'axios';
import { ContactFormValues } from '../pages/ContactPage/config';

export const sendEmail = async (contactFormValues: ContactFormValues): Promise<boolean> => {
    const { data } = await axios.post(`${process.env.REACT_APP_AWS_URL}/send-email`, {
        ...contactFormValues,
    });

    return data.statusCode === 202;
};
