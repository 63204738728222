import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    errorText: {
        marginLeft: 0,
        marginRight: 0,
        textAlign: 'right',
        color: '#E44447 !important',
        fontWeight: 600,
        fontSize: 12,
        lineHeight: '16.8px',
    },
    container: {
        position: 'relative',
    },
    statusIcon: {
        position: 'absolute',
        right: -27,
        top: 22,
    },
    errorIcon: {
        position: 'absolute',
        right: -22,
        top: 22,
    },
});
