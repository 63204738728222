import React, { FC, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpenInNew from '@material-ui/icons/OpenInNew';
import { useStyles } from './styles';
import { useStyles as useDialogStyles } from '../../../components/InfoDialog/styles';
import { SearchForm } from '../config';
import InfoDialog from '../../../components/InfoDialog';

const SearchSubmitDialog: FC = () => {
    const classes = useStyles();
    const dialogClasses = useDialogStyles();

    const [open, setOpen] = useState<boolean>(false);
    const { submitForm, values } = useFormikContext<SearchForm>();

    const openDialog = () => {
        if (values.pickedCompany) {
            setOpen(true);
        }
    };

    const renderDialog = () => (
        <InfoDialog open={open} setOpen={setOpen} action={submitForm}>
            <Typography className={dialogClasses.text}>
                {`Make sure to have your `}
                <span className={dialogClasses.textBlue}>authentication code</span>
                {` and `}
                <span className={dialogClasses.textBlue}>personal card payment details</span>
                {` ready because you will need these shortly.`}
            </Typography>

            <Accordion classes={{ root: classes.accordionRoot }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    classes={{
                        root: classes.accordionSummaryRoot,
                        content: classes.accordionSummaryContent,
                        expanded: classes.accordionExpanded,
                    }}
                >
                    <Typography className={classes.accordionHeader}>
                        What is an authentication code?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <Typography className={classes.accordionText}>
                        {`It is a 6 digit alphanumeric code needed to file online and is the equivalent of a
                        company officer’s signature. You will have received an Authentication Code by letter
                        from Companies House when you had set up your company. If you don’t know it or lost
                        it, you can request a new one from the `}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.gov.uk/guidance/company-authentication-codes-for-online-filing"
                        >
                            government website <OpenInNew className={classes.linkIcon} />
                        </a>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </InfoDialog>
    );

    const renderButton = () => (
        <Button
            variant="contained"
            color="primary"
            onClick={openDialog}
            classes={{ root: classes.startedButtonRoot, label: classes.startedButtonLabel }}
        >
            Get started
        </Button>
    );

    return (
        <>
            {renderButton()}
            {renderDialog()}
        </>
    );
};

export default SearchSubmitDialog;
