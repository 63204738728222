import axios from 'axios';

export interface PaymentIntent {
    id: string;
    errorMessage?: string;
    charges?: [
        {
            id: string;
            amount: number;
            billing_details: {
                email: string;
            };
            payment_method_details: {
                card: {
                    last4: string;
                    brand: string;
                };
            };
        },
    ];
}

export const getCustomerId = async (email: string): Promise<string> => {
    try {
        const { data } = await axios.post(`${process.env.REACT_APP_AWS_URL}/customer`, {
            email,
        });

        return data.customerId;
    } catch (e) {
        return '';
    }
};

export const createPaymentIntent = async (
    customerId: string,
    customerEmail: string,
    paymentMethodId: string,
): Promise<PaymentIntent> => {
    try {
        const { data } = await axios.post(`${process.env.REACT_APP_AWS_URL}/create-payment-intent`, {
            customerId,
            customerEmail,
            paymentMethodId,
        });

        return data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
        return e?.response.data;
    }
};
