import * as yup from 'yup';

export interface SearchForm {
    authCode: string;
}

export const initialValues: SearchForm = { authCode: '' };

export const validationSchema = yup.object().shape({
    authCode: yup.string().required('Authentication code is required'),
});
