import { FC, useContext } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { validationSchema, initialValues, SearchForm } from '../config';

import SearchCompanyForm from '../SearchCompanyForm';
import CompanyContext from '../../../components/CompanyContext';
import { ROUTES } from '../../../config/constants';
import { defaultFinancialData } from '../../../models/CompanyFinancialData.model';

const SearchCompany: FC = () => {
    const history = useHistory();
    const { company, setCompany } = useContext(CompanyContext);

    const handleSubmitForm = (values: SearchForm, { setSubmitting }: FormikHelpers<SearchForm>) => {
        if (values.pickedCompany) {
            setCompany({
                ...company,
                ...values.pickedCompany,
                authCode: company.authCode,
                financialData: defaultFinancialData,
                financialDataLoaded: false,
            });
            history.push(ROUTES.PUBLIC.FINANCIAL);
        }

        setSubmitting(false);
    };
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={
                    company.title ? { search: company.title, pickedCompany: company } : initialValues
                }
                validationSchema={validationSchema}
                onSubmit={handleSubmitForm}
            >
                <SearchCompanyForm />
            </Formik>
        </>
    );
};

export default SearchCompany;
