import { ROUTES } from './constants';

import LandingPage from '../pages/LandingPage';
import FinancialPage from '../pages/FinancialPage';
import PaymentsPage from '../pages/PaymentsPage';
import AuthenticationPage from '../pages/AuthenticationPage';
import PaymentConfirmationPage from '../pages/PaymentConfirmationPage';
import ContactPage from '../pages/ContactPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';

export const routes = [
    {
        path: ROUTES.PUBLIC.LANDING,
        component: LandingPage,
        exact: true,
    },
    {
        path: ROUTES.PUBLIC.FINANCIAL,
        component: FinancialPage,
        exact: true,
    },
    {
        path: ROUTES.PUBLIC.AUTHENTICATION,
        component: AuthenticationPage,
        exact: true,
    },
    {
        path: ROUTES.PUBLIC.PAYMENTS,
        component: PaymentsPage,
        exact: true,
    },
    {
        path: ROUTES.PUBLIC.PAYMENT_CONFIRMATION,
        component: PaymentConfirmationPage,
        exact: true,
    },
    {
        path: ROUTES.PUBLIC.CONTACT,
        component: ContactPage,
        exact: true,
    },
    {
        path: ROUTES.PUBLIC.PRIVACY_POLICY,
        component: PrivacyPolicyPage,
        exact: true,
    },
];
