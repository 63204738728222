import { makeStyles } from '@material-ui/core';
import { theme } from '../../config/theme';

export const useStyles = makeStyles({
    container: {
        [theme.breakpoints.up(960)]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            paddingTop: '3rem',
            flexGrow: 1,
        },
        [theme.breakpoints.down(960)]: {
            padding: '1rem',
        },
    },
    textWrapper: {
        maxWidth: 1000,
    },
    pageTitle: {
        fontSize: '2rem',
        marginTop: '2rem',
        marginBottom: '2rem',
        [theme.breakpoints.down('sm')]: {
            marginTop: '1rem',
            marginBottom: '1rem',
        },
    },
    subtitle: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        width: '100%',
        marginBottom: '1rem',
    },
    text: {
        fontSize: '1.2rem',
        marginBottom: 16,
        textAlign: 'left',
        width: '100%',
    },
    section: {
        marginBottom: '3rem',
    },
});
