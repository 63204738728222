import React, { FC, useContext, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Hidden } from '@material-ui/core';
import CustomStepper from '../../components/CustomStepper';
import { useStyles } from './styles';
import { ROUTES, STEPPER_CONFIG } from '../../config/constants';
import CompanyContext from '../../components/CompanyContext';
import { initialCompanyValue } from '../../models/Company';

const StepperAndBackButton: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { company, setCompany } = useContext(CompanyContext);
    const { pathname } = useLocation();
    const STEP = STEPPER_CONFIG.find(({ path }) => path === pathname);

    const resetContextIfStartAgain = () => {
        if (STEP?.prevName === 'Start Again') {
            setCompany(initialCompanyValue);
        }
    };

    useEffect(() => {
        if (STEP && STEP.step > 0 && !company.company_number) {
            history.push(ROUTES.PUBLIC.LANDING);
        } else if (STEP && STEP.step === 3 && !company.authCode) {
            history.push(ROUTES.PUBLIC.AUTHENTICATION);
        } else if (STEP && STEP.step === 4 && !company.payment.transactionId) {
            history.push(ROUTES.PUBLIC.PAYMENTS);
        }
    }, [STEP, history, company]);

    return (
        <div className={classes.gridContainer}>
            <Hidden smDown>
                <Link to={STEP?.prevPath || ''} className={classes.link} onClick={resetContextIfStartAgain}>
                    <KeyboardBackspaceIcon className={classes.icon} />
                    {STEP?.prevName}
                </Link>
            </Hidden>
            <CustomStepper activeStep={STEP?.step as number} />
            <div />
        </div>
    );
};

export default StepperAndBackButton;
