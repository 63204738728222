import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    pageTitle: {
        marginTop: 46,
        marginBottom: 30,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
        },
    },
    stripeIcon: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20,
        },
    },
}));
