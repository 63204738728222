import { Autocomplete } from 'formik-material-ui-lab';
import React, { FC } from 'react';
import clsx from 'clsx';
import { StandardTextFieldProps } from '@material-ui/core/TextField/TextField';
import { Field, useField, useFormikContext } from 'formik';
import TextField from '@material-ui/core/TextField';
import { useStyles } from './styles';
import Company from '../../../models/Company';
import { SearchForm } from '../config';

interface Props {
    name: string;
    inputFormikName: string;
    placeholder?: string;
    loadingOptions: boolean;
    options: Company[];
    onChange?: (event: React.ChangeEvent<{ value: unknown }>, option: Company) => void;
}

const CompaniesFormikAutocomplete: FC<Props> = ({
    name,
    placeholder = '',
    inputFormikName,
    loadingOptions,
    options,
    ...otherProps
}) => {
    const meta = useField(name)[1];
    const { setFieldValue, values } = useFormikContext<SearchForm>();
    const { touched, error } = meta;
    const classes = useStyles();

    return (
        <Field
            name={name}
            component={Autocomplete}
            options={options}
            disablePortal
            noOptionsText={
                loadingOptions
                    ? ''
                    : `
                Sorry, we can’t find any matches for “${values.search}”. Please check your spelling and make sure the company is registered.
            `
            }
            onInputChange={(e: React.ChangeEvent<{ value: unknown }>, value: string) =>
                setFieldValue(inputFormikName, value)
            }
            getOptionLabel={(option: Company) => option.title || ''}
            getOptionSelected={(option: Company, value: Company) => option.title === value.title}
            filterOptions={() => options}
            classes={{
                root: classes.searchInput,
                popper: clsx(classes.popper, {
                    [classes.popperNoOptions]: !options.length,
                    [classes.popperHidden]: !values.search.length,
                }),
                paper: clsx(classes.popperPaper, { [classes.paperNoOptions]: !options.length }),
                noOptions: classes.noOptions,
                listbox: classes.list,
                option: classes.option,
                input: classes.input,
            }}
            renderInput={(params: StandardTextFieldProps) => (
                <TextField
                    {...params}
                    id={name}
                    margin="normal"
                    placeholder={placeholder}
                    error={touched && !!error}
                    helperText={error}
                    InputProps={{ ...params.InputProps, disableUnderline: true }}
                    className={classes.textField}
                />
            )}
            {...otherProps}
        />
    );
};

CompaniesFormikAutocomplete.defaultProps = {
    placeholder: undefined,
    onChange: undefined,
};

export default CompaniesFormikAutocomplete;
