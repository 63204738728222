import { Button } from '@material-ui/core';
import { FC } from 'react';
import OpenInNew from '@material-ui/icons/OpenInNew';
import { useStyles } from '../styles';

interface Props {
    openTooltipOrCollapse: () => void;
}

const WhoIsFor: FC<Props> = ({ openTooltipOrCollapse }) => {
    const classes = useStyles();

    return (
        <>
            <p>
                <strong>
                    If your company has been{' '}
                    <Button
                        variant="text"
                        disableRipple
                        onClick={openTooltipOrCollapse}
                        className={classes.dormantButton}
                    >
                        dormant
                    </Button>{' '}
                    since incorporation and for its full financial year, this service is for you.
                </strong>{' '}
                You can save time and hassle with us in filing your dormant accounts to Companies House. This
                service is only for{' '}
                <a
                    href="https://www.gov.uk/annual-accounts/microentities-small-and-dormant-companies"
                    target="_blank"
                    rel="noreferrer"
                >
                    small <OpenInNew className={classes.newLinkIcon} />
                </a>{' '}
                companies limited by shares or by guarantee, not for other entities. Your company cannot be a
                subsidiary of another company.
            </p>
            <p>
                Even if your company was dormant, you still have to file accounts to Companies House as long
                as you haven’t closed your company down. Dormant accounts are allowed for the financial year
                in which your company was dormant. For example, if it was dormant for the financial year ended
                31 December 2020, but afterwards became active, you can still file dormant accounts for that
                year but not the subsequent year.
            </p>

            <p>
                Dormant companies do not usually need to file a Corporation Tax return, but you will need to
                notify HMRC in advance that your company will be dormant if it meets{' '}
                <a
                    href="https://www.gov.uk/dormant-company/dormant-for-corporation-tax"
                    target="_blankH"
                    rel="noreferrer"
                >
                    HMRC’s definition <OpenInNew className={classes.newLinkIcon} />
                </a>
                , which can be done by{' '}
                <a
                    href="https://www.gov.uk/government/organisations/hm-revenue-customs/contact/corporation-tax-enquiries"
                    target="_blank"
                    rel="noreferrer"
                >
                    phone or online <OpenInNew className={classes.newLinkIcon} />
                </a>
                . They may still require you to send a nil return if you have been sent a notice letter. Quite
                often in the first year, HMRC may issue you a notice anyway because they don’t know you’re
                dormant, so make sure to check to avoid any penalties.
            </p>
        </>
    );
};

export default WhoIsFor;
