import * as yup from 'yup';
import Company from '../../models/Company';

export interface SearchForm {
    search: string;
    pickedCompany: Company | null;
}

export const initialValues: SearchForm = { search: '', pickedCompany: null };

export const validationSchema = yup.object().shape({
    search: yup.string(),
});
