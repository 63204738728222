import { createContext } from 'react';

const MenuContext = createContext<{
    openTooltips: boolean[];
    openTooltip: (key: number) => void;
    handleClickAway: (key: number) => void;
    closeAllTooltips: () => void;
}>({
    openTooltips: [false, false, false, false, false],
    openTooltip: () => {},
    handleClickAway: () => {},
    closeAllTooltips: () => {},
});

export default MenuContext;
