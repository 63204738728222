import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required.'),
    message: yup.string().required('Message is required.'),
    email: yup
        .string()
        .email('This email is not valid. Please check your email and try again.')
        .required('Email is required.'),
});

export interface ContactFormValues {
    name: string;
    email: string;
    message: string;
}

export const initialValues: ContactFormValues = { name: '', email: '', message: '' };
