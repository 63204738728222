import axios from 'axios';
import dayjs from 'dayjs';
import CompanyFinancialDataModel, {
    BalanceSheet,
    balanceSheetFields,
    defaultFinancialData,
} from '../models/CompanyFinancialData.model';
import balanceSheetTemplate from '../config/documentTemplate';
import { CompanyDetails, PersonOfInterest } from '../models/Company';

interface FillingHistoryItem {
    action_date: string;
    date: string;
    category: string;
    type: string;
    links: {
        document_metadata: string;
    };
}

const isValidForSubmission = (balanceSheet: CompanyFinancialDataModel): boolean => {
    return new Date(balanceSheet.endDate).getTime() < new Date().getTime();
};

const getRowText = (row: HTMLElement): string => {
    return (
        row?.innerText
            ?.toLowerCase()
            ?.replaceAll('\n', '')
            ?.trim()
            ?.match(/^\s*(.*?)(?=\(\d|\d|$)/)?.[0]
            ?.replaceAll(':', '')
            ?.trim() || ''
    );
};

const readValue = (row: HTMLElement, index = 0): number => {
    return (
        (row?.getElementsByTagName('ix:nonfraction')[index].getAttribute('sign') === '-' ||
        // @ts-ignore
        row?.getElementsByTagName('ix:nonfraction')[index].parentElement.innerText.includes('(')
            ? -1
            : 1) *
            // @ts-ignore
            parseInt(row?.getElementsByTagName('ix:nonfraction')[index].innerText.replaceAll(',', ''), 10) ||
        0
    );
};

const getRows = (iframe: HTMLIFrameElement): HTMLElement[] => {
    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
    const rows: HTMLElement[] = [];

    if (!iframeDoc) return [];

    const elements = Array.from(iframeDoc.getElementsByTagName('ix:nonfraction'));
    for (let i = 0; i < elements.length; i += 1) {
        const element = elements[i];

        let add = true;

        let row: HTMLElement | null | undefined;
        if (/\b[a-zA-Z]+\b/.test(element.parentElement?.innerText || '')) {
            row = element.parentElement;
        } else if (/\b[a-zA-Z]+\b/.test(element.parentElement?.parentElement?.innerText || '')) {
            row = element.parentElement?.parentElement;
        } else if (
            /\b[a-zA-Z]+\b/.test(element.parentElement?.parentElement?.parentElement?.innerText || '')
        ) {
            row = element.parentElement?.parentElement?.parentElement;
        } else if (
            /\b[a-zA-Z]+\b/.test(
                element.parentElement?.parentElement?.parentElement?.parentElement?.innerText || '',
            )
        ) {
            row = element.parentElement?.parentElement?.parentElement?.parentElement;
        }

        for (let j = 0; j < rows.length; j += 1) {
            if (!row || row?.innerText === rows[j].innerText) {
                add = false;
            }
        }

        if (add && row) {
            rows.push(row);
        }
    }
    // console.log(
    //     'rows',
    //     rows.map(row => `${row.innerText} -> ${getRowText(row)}: ${readValue(row, 0)}`),
    // );
    return rows;
};

const getCompanyInfo = async (companyNumber: string): Promise<CompanyDetails> => {
    const { data } = await axios.get(
        `${process.env.REACT_APP_AWS_URL}/company-info?companyNumber=${companyNumber}`,
    );
    return data;
};

const readYearlyReports = (
    financialData: CompanyFinancialDataModel,
    iframe: HTMLIFrameElement,
): CompanyFinancialDataModel => {
    const endYear: string = financialData.endDate.split('-')[0];

    const rows = getRows(iframe);

    const currentYearBalanceSheet: BalanceSheet = { ...financialData.currentYear, year: endYear };

    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
    const employeesFields = iframeDoc?.getElementsByName('core:AverageNumberEmployeesDuringPeriod');
    if (employeesFields && employeesFields.length) {
        financialData.employees = parseInt(employeesFields[0].innerText, 10);
    }

    Object.keys(balanceSheetFields).forEach((key: string) => {
        // @ts-ignore
        currentYearBalanceSheet[key].value = 0;
        // @ts-ignore
        currentYearBalanceSheet[key].fields = {};

        rows.forEach((row: HTMLElement) => {
            const rowText = getRowText(row);
            if (
                balanceSheetFields[key].total.some(field => {
                    return rowText === field && (!rowText.includes('net') || field.includes('net'));
                })
            ) {
                try {
                    // @ts-ignore
                    currentYearBalanceSheet[key].value = readValue(row);
                    // @ts-ignore
                    currentYearBalanceSheet[key].fields[rowText] = readValue(row);
                } catch (e) {
                    // console.error(e);
                }
            }
        });

        rows.forEach((row: HTMLElement) => {
            const rowText = row?.innerText?.toLowerCase()?.match(/^(.*?)(?=\(\d|\d|$)/)?.[0] || '';

            if (balanceSheetFields[key].summedUp.some(field => rowText === field)) {
                try {
                    // @ts-ignore
                    if (!currentYearBalanceSheet[key]) {
                        // @ts-ignore
                        currentYearBalanceSheet[key].value += readValue(row);
                    }
                    // @ts-ignore
                    currentYearBalanceSheet[key].fields[rowText] = readValue(row);
                } catch (e) {
                    // console.error(e);
                }
            }
        });
    });

    return {
        ...financialData,
        currentYear: currentYearBalanceSheet,
        hasPreviousYear:
            !!currentYearBalanceSheet.capitalAndReserves.value ||
            !!currentYearBalanceSheet.currentAssets.value ||
            !!currentYearBalanceSheet.fixedAssets.value,
    };
};

const createIframe = (document: string): HTMLIFrameElement => {
    const iframe = window.document.createElement('iframe');
    iframe.id = 'document-iframe';
    iframe.style.display = 'none';
    if (!window.document.getElementById('document-iframe')) {
        window.document.body.appendChild(iframe);
    }

    if (iframe.contentWindow) iframe.contentWindow.document.write(document);

    return iframe;
};

const getBalanceSheetFromDocument = (
    document: string,
    financialData: CompanyFinancialDataModel,
): CompanyFinancialDataModel => {
    const iframe: HTMLIFrameElement = createIframe(document);

    const updatedFinancialData = readYearlyReports(financialData, iframe);

    iframe.remove();

    return updatedFinancialData;
};

const removeNotesFromDocument = (document: string): string => {
    const partsOccurrences: number = (document.toLowerCase().match(/section 477/gi) || []).length;

    if (partsOccurrences >= 1) {
        const parts = document.split(new RegExp(/section 477/gi));
        return parts[0];
    }

    return document;
};

const getCompanyDirector = async (companyNumber: string): Promise<PersonOfInterest> => {
    const { data } = await axios.get(
        `${process.env.REACT_APP_AWS_URL}/company-directors?companyNumber=${companyNumber}`,
    );
    return data;
};

export const generateNewDocument = async (financialData: CompanyFinancialDataModel): Promise<string> => {
    const personOfInterest: PersonOfInterest = await getCompanyDirector(financialData.companyNumber);
    const companyDetails: CompanyDetails = await getCompanyInfo(financialData.companyNumber);

    let balanceSheet = balanceSheetTemplate.text
        .replaceAll('{{COMPANY_NAME}}', financialData.companyName.replaceAll('&', '&amp;'))
        .replaceAll('{{COMPANY_NUMBER}}', financialData.companyNumber);

    if (!financialData.hasPreviousYear) {
        balanceSheet = balanceSheet.replaceAll(
            /(<!-- delete_if_not_previous_year-->)[\S\s]*?(<!-- end_delete_if_not_previous_year-->)/gim,
            '',
        );
    }

    balanceSheet = balanceSheet
        .replaceAll('{{START_DATE_F1}}', companyDetails.accounts.next_accounts?.period_start_on)
        .replaceAll('{{END_DATE_F1}}', companyDetails.accounts.next_accounts?.period_end_on)
        .replaceAll(
            '{{END_DATE_F2}}',
            dayjs(companyDetails.accounts.next_accounts?.period_end_on).format(balanceSheetTemplate.F2),
        )
        .replaceAll(
            '{{LAST_END_DATE_F1}}',
            dayjs(companyDetails.accounts.next_accounts?.period_end_on)
                .add(-1, 'years')
                .format(balanceSheetTemplate.F1),
        )
        .replaceAll(
            '{{CURRENT_YEAR}}',
            dayjs(companyDetails.accounts.next_accounts?.period_end_on).format('YYYY'),
        )
        .replaceAll(
            '{{PREVIOUS_YEAR}}',
            dayjs(companyDetails.accounts.next_accounts?.period_end_on).add(-1, 'years').format('YYYY'),
        )
        .replaceAll('{{DIRECTOR_NAME}}', personOfInterest.items[0].name)
        .replaceAll('{{DATE_SIGNED_F2}}', dayjs().format(balanceSheetTemplate.F2));

    const currentAssetsCY = financialData.currentYear.currentAssets.value;

    const netCurrentAssetsLiabilitiesCY =
        financialData.currentYear.currentAssets.value -
        Math.abs(financialData.currentYear.currentCreditors.value);

    const totalAssetsLessCurrentLiabilitiesCY =
        financialData.currentYear.currentAssets.value +
        financialData.currentYear.fixedAssets.value -
        Math.abs(financialData.currentYear.currentCreditors.value);

    const netAssetsCY = financialData.currentYear.netAssets.value;
    const equityCY = financialData.currentYear.capitalAndReserves.value;

    balanceSheet = balanceSheet
        .replaceAll('{{CurrentAssets_CURRENT_P1}}', currentAssetsCY < 0 ? '(' : '')
        .replaceAll('{{CurrentAssets_CURRENT_P2}}', currentAssetsCY < 0 ? ')' : '')
        .replaceAll('{{CurrentAssetsSign_CURRENT}}', currentAssetsCY < 0 ? 'sign="-"' : '')
        .replaceAll(
            '{{CurrentAssets_CURRENT}}',
            Math.abs(currentAssetsCY)
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
        )

        .replaceAll('{{CurrentAssets_PREVIOUS_P1}}', currentAssetsCY < 0 ? '(' : '')
        .replaceAll('{{CurrentAssets_PREVIOUS_P2}}', currentAssetsCY < 0 ? ')' : '')
        .replaceAll('{{CurrentAssetsSign_PREVIOUS}}', currentAssetsCY < 0 ? 'sign="-"' : '')
        .replaceAll(
            '{{CurrentAssets_PREVIOUS}}',
            Math.abs(currentAssetsCY)
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
        )

        .replaceAll(
            '{{NetCurrentAssetsLiabilities_CURRENT_P1}}',
            netCurrentAssetsLiabilitiesCY < 0 ? '(' : '',
        )
        .replaceAll(
            '{{NetCurrentAssetsLiabilities_CURRENT_P2}}',
            netCurrentAssetsLiabilitiesCY < 0 ? ')' : '',
        )
        .replaceAll(
            '{{NetCurrentAssetsLiabilitiesSign_CURRENT}}',
            netCurrentAssetsLiabilitiesCY < 0 ? 'sign="-"' : '',
        )
        .replaceAll(
            '{{NetCurrentAssetsLiabilities_CURRENT}}',
            Math.abs(netCurrentAssetsLiabilitiesCY)
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
        )

        .replaceAll(
            '{{NetCurrentAssetsLiabilities_PREVIOUS_P1}}',
            netCurrentAssetsLiabilitiesCY < 0 ? '(' : '',
        )
        .replaceAll(
            '{{NetCurrentAssetsLiabilities_PREVIOUS_P2}}',
            netCurrentAssetsLiabilitiesCY < 0 ? ')' : '',
        )
        .replaceAll(
            '{{NetCurrentAssetsLiabilitiesSign_PREVIOUS}}',
            netCurrentAssetsLiabilitiesCY < 0 ? 'sign="-"' : '',
        )
        .replaceAll(
            '{{NetCurrentAssetsLiabilities_PREVIOUS}}',
            Math.abs(netCurrentAssetsLiabilitiesCY)
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
        )

        .replaceAll(
            '{{TotalAssetsLessCurrentLiabilities_CURRENT_P1}}',
            totalAssetsLessCurrentLiabilitiesCY < 0 ? '(' : '',
        )
        .replaceAll(
            '{{TotalAssetsLessCurrentLiabilities_CURRENT_P2}}',
            totalAssetsLessCurrentLiabilitiesCY < 0 ? ')' : '',
        )
        .replaceAll(
            '{{TotalAssetsLessCurrentLiabilitiesSign_CURRENT}}',
            totalAssetsLessCurrentLiabilitiesCY < 0 ? 'sign="-"' : '',
        )
        .replaceAll(
            '{{TotalAssetsLessCurrentLiabilities_CURRENT}}',
            Math.abs(totalAssetsLessCurrentLiabilitiesCY)
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
        )

        .replaceAll(
            '{{TotalAssetsLessCurrentLiabilities_PREVIOUS_P1}}',
            totalAssetsLessCurrentLiabilitiesCY < 0 ? '(' : '',
        )
        .replaceAll(
            '{{TotalAssetsLessCurrentLiabilities_PREVIOUS_P2}}',
            totalAssetsLessCurrentLiabilitiesCY < 0 ? ')' : '',
        )
        .replaceAll(
            '{{TotalAssetsLessCurrentLiabilitiesSign_PREVIOUS}}',
            totalAssetsLessCurrentLiabilitiesCY < 0 ? 'sign="-"' : '',
        )
        .replaceAll(
            '{{TotalAssetsLessCurrentLiabilities_PREVIOUS}}',
            Math.abs(totalAssetsLessCurrentLiabilitiesCY)
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
        )

        .replaceAll('{{NetAssetsLiabilities_CURRENT_P1}}', netAssetsCY < 0 ? '(' : '')
        .replaceAll('{{NetAssetsLiabilities_CURRENT_P2}}', netAssetsCY < 0 ? ')' : '')
        .replaceAll('{{NetAssetsLiabilitiesSign_CURRENT}}', netAssetsCY < 0 ? 'sign="-"' : '')
        .replaceAll(
            '{{NetAssetsLiabilities_CURRENT}}',
            Math.abs(netAssetsCY)
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
        )

        .replaceAll('{{NetAssetsLiabilities_PREVIOUS_P1}}', netAssetsCY < 0 ? '(' : '')
        .replaceAll('{{NetAssetsLiabilities_PREVIOUS_P2}}', netAssetsCY < 0 ? ')' : '')
        .replaceAll('{{NetAssetsLiabilitiesSign_PREVIOUS}}', netAssetsCY < 0 ? 'sign="-"' : '')
        .replaceAll(
            '{{NetAssetsLiabilities_PREVIOUS}}',
            Math.abs(netAssetsCY)
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
        )

        .replaceAll('{{Equity_CURRENT_P1}}', equityCY < 0 ? '(' : '')
        .replaceAll('{{Equity_CURRENT_P2}}', equityCY < 0 ? ')' : '')
        .replaceAll('{{EquitySign_CURRENT}}', equityCY < 0 ? 'sign="-"' : '')
        .replaceAll(
            '{{Equity_CURRENT}}',
            Math.abs(equityCY)
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
        )

        .replaceAll('{{Equity_PREVIOUS_P1}}', equityCY < 0 ? '(' : '')
        .replaceAll('{{Equity_PREVIOUS_P2}}', equityCY < 0 ? ')' : '')
        .replaceAll('{{EquitySign_PREVIOUS}}', equityCY < 0 ? 'sign="-"' : '')
        .replaceAll(
            '{{Equity_PREVIOUS}}',
            Math.abs(equityCY)
                .toString()
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
        );

    return balanceSheet;
};

const downloadCompanyIxbrlDocument = async (documentId: string): Promise<string> => {
    const axiosResponse = await axios.get(
        `${process.env.REACT_APP_AWS_URL}/fetch-document?documentId=${documentId}`,
        {
            headers: { accept: 'application/xhtml+xml' },
        },
    );

    return axiosResponse.data;
};

const getCHDocumentId = async (companyId: string): Promise<string | undefined> => {
    const { data } = await axios.get(
        `${process.env.REACT_APP_AWS_URL}/filing-history?companyNumber=${companyId}`,
    );

    const filings = data.items
        ?.filter((item: FillingHistoryItem) => item.type === 'AA' && item.category === 'accounts')
        ?.sort(
            (a: FillingHistoryItem, b: FillingHistoryItem) =>
                new Date(b.action_date).getTime() - new Date(a.action_date).getTime(),
        );

    return filings?.[0]?.links.document_metadata?.split('/')?.pop();
};

export const isValidForSubmissionNewCompany = async (companyNumber: string): Promise<boolean> => {
    const companyDetails: CompanyDetails = await getCompanyInfo(companyNumber);
    if (companyDetails.accounts.next_accounts?.period_end_on)
        return (
            new Date(companyDetails.accounts.next_accounts?.period_end_on).getTime() < new Date().getTime()
        );
    return true;
};

export const getCompanyFinancialData = async (
    companyNumber: string,
    companyName: string,
): Promise<CompanyFinancialDataModel> => {
    const companyDetails = await getCompanyInfo(companyNumber);

    const startDate = companyDetails.accounts.next_accounts?.period_start_on;
    const endDate =
        companyDetails.accounts.next_accounts?.period_end_on ||
        `${dayjs()
            .add(
                companyDetails.accounts.accounting_reference_date.month < dayjs().format('MM') ? 1 : 0,
                'years',
            )
            .format('YYYY')}-${companyDetails.accounts.accounting_reference_date.month}-${
            companyDetails.accounts.accounting_reference_date.day
        }`;

    let financialData: CompanyFinancialDataModel = {
        ...defaultFinancialData,
        companyName,
        companyNumber,
        startDate,
        endDate,
        currentYear: {
            ...defaultFinancialData.currentYear,
            year: dayjs(endDate).format('YYYY'),
        },
    };

    const documentId: string | undefined = await getCHDocumentId(companyNumber);
    if (!documentId) {
        return {
            ...financialData,
            newDocument: await generateNewDocument(financialData),
            validForSubmission: await isValidForSubmissionNewCompany(financialData.companyNumber),
            error: true,
            errorString: '',
            hasPreviousYear: false,
        };
    }

    try {
        const document = await downloadCompanyIxbrlDocument(documentId);
        financialData = {
            ...financialData,
            oldDocument: document,
            validForSubmission: isValidForSubmission(financialData),
            hasPreviousYear: true,
        };

        financialData = getBalanceSheetFromDocument(removeNotesFromDocument(document), financialData);
        financialData = {
            ...financialData,
            newDocument: await generateNewDocument(financialData),
        };

        return financialData;
    } catch (e) {
        return {
            ...financialData,
            newDocument: await generateNewDocument(financialData),
            error: true,
            errorString: 'Could not read the balance sheet, it is not a valid iXbrl document',
        };
    }
};
