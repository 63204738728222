import React, { FC, useContext } from 'react';
import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';
import { useStyles as useFinancialConfirmationStyles } from '../FinancialDataConfirmation/styles';
import InfoIcon from '../../../assets/images/info-icon.svg';
import CustomTooltip from '../../../components/CustomTooltip';
import MenuContext from '../../../components/MenuContext';

const DormancyDescription: FC = () => {
    const classes = useStyles();
    const { openTooltip } = useContext(MenuContext);

    const financialConfirmationStyles = useFinancialConfirmationStyles();

    const tooltipContent = () => (
        <>
            {`A company is dormant if has not traded or did not have any transactions for the full financial year 
            apart from a few listed `}
            <Button disableRipple variant="text" onClick={() => openTooltip(0)} className={classes.button}>
                <strong>here.</strong>
            </Button>
            {` Dormant companies are typically those that are new and in their first year,
            but they could also be older companies that simply haven’t been active for a given year.`}
            <br />
            <br />
            {`If you’re still not sure then `}
            <Button disableRipple variant="text" onClick={() => openTooltip(0)} className={classes.button}>
                <strong>see more information here.</strong>
            </Button>
        </>
    );

    return (
        <>
            <Typography
                variant="h1"
                className={clsx(financialConfirmationStyles.pageTitle, classes.moreTopSpace)}
            >
                {`Dormancy Confirmation `}
                <CustomTooltip interactive placement="right-start" title={tooltipContent()}>
                    <img src={InfoIcon} alt="Info" width="16" height="16" className={classes.infoIcon} />
                </CustomTooltip>
            </Typography>

            <p className={financialConfirmationStyles.confirmText}>
                Do you confirm that the company has been dormant since incorporation and for the full
                financial year?
            </p>
        </>
    );
};

export default DormancyDescription;
