import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    popper: {
        pointerEvents: 'none',
        marginLeft: 20,
        marginTop: -20,
        borderRadius: 5,
        boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.05)',
    },
    pageTitle: {
        marginBottom: 18,
        marginTop: 80,
    },
    moreTopSpace: {
        marginTop: 25,
    },
    arrow: {
        position: 'absolute',
        color: 'white',
        fontSize: 55,
        left: -30,
    },
    paper: {
        padding: 22,
        width: 320,
        boxShadow: 'none',
    },
    popperText: {
        fontSize: 14,
        lineHeight: '130%',
    },
    icon: {
        fontSize: 22,
        fontWeight: 600,
        color: '#C7C7C7',
        marginLeft: 5,
        marginBottom: -3,
    },
    infoIcon: {
        marginTop: 2,
    },
    button: {
        textTransform: 'none',
        padding: 0,
        marginBottom: 0,
        marginTop: -4,
        minWidth: 'auto',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0)',
        },
    },
});
