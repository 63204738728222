const balanceSheetTemplate = {
    F1: 'YYYY-MM-DD',
    F2: 'DD MMMM YYYY',
    F3: 'DD.MM.YY',
    F4: 'DD.MM.YYYY',
    text:
        '<?xml version="1.0" encoding="UTF-8"?>' +
        '<html xmlns="http://www.w3.org/1999/xhtml" xmlns:core="http://xbrl.frc.org.uk/fr/2021-01-01/core"' +
        '      xmlns:countries="http://xbrl.frc.org.uk/cd/2021-01-01/countries"' +
        '      xmlns:curr="http://xbrl.frc.org.uk/cd/2021-01-01/currencies"' +
        '      xmlns:direp="http://xbrl.frc.org.uk/reports/2021-01-01/direp" xmlns:fn="http://www.w3.org/2005/xpath-functions"' +
        '      xmlns:iso4217="http://www.xbrl.org/2003/iso4217" xmlns:ix="http://www.xbrl.org/2013/inlineXBRL"' +
        '      xmlns:ixt="http://www.xbrl.org/inlineXBRL/transformation/2010-04-20"' +
        '      xmlns:ixt2="http://www.xbrl.org/inlineXBRL/transformation/2011-07-31"' +
        '      xmlns:link="http://www.xbrl.org/2003/linkbase" xmlns:uk-bus="http://xbrl.frc.org.uk/cd/2021-01-01/business"' +
        '      xmlns:xbrldi="http://xbrl.org/2006/xbrldi" xmlns:xbrli="http://www.xbrl.org/2003/instance"' +
        '      xmlns:xlink="http://www.w3.org/1999/xlink" xml:lang="en">' +
        '    <head>' +
        '        <meta content="Companies House WebFiling - AA02 Form" name="generator"/>' +
        '        <meta content="text/html; charset=UTF-8" http-equiv="Content-Type"/>' +
        '        <style type="text/css">' +
        '           body { font-family: "Times New Roman",Times,Serif; }' +
        '           tr,td,th,tbody {padding: 0px; margin: 0px;}' +
        '           .hidden { display: none; }' +
        '           div.pagebreak { page-break-after: always; }' +
        '           div.accountspage { width: 100%; }' +
        '           div.titlepage { font-weight: bold; margin-top: 5em; text-align: center;}' +
        '           div.accountsheader{ font-weight: bold; width: 100%; display: block; }' +
        '           span.left  { float: left; width: 70%; }' +
        '           span.right { float: right; width: 30%; text-align: right;  }' +
        '           #balancesheet { margin-top: 1cm; width: 80%; margin-left: 10%; display: block; padding-top: 1cm; }' +
        '           #balancesheet table {margin-top: 1cm;  width: 100% }' +
        '           #balancesheet table td { padding-top: 5px; padding-bottom: 1px; }' +
        '           #notes { margin-top: 1cm; width: 80%; margin-left: 10%; display: block; }' +
        '           #notes table {width:  100%;} ' +
        '           #statements {margin-top: 1cm; width:80%; margin-left: 10%;}' +
        '           #balancesheet th {text-align: left;}' +
        '           tr.indent &gt; *:first-child {padding-left: 1cm;}' +
        '           #balancesheet .figure {text-align: right;}' +
        '           #notes .figure {text-align: right;}' +
        '           td.number  { text-align: right; }' +
        '           #balancesheet td.total {font-weight: bold; border-color: black; border-top-width: 1px; border-bottom-width: 2px; border-style: solid none solid none; }' +
        '           tr.total td.figure {font-weight: bold; border-color: black; border-top-width: 1px; border-bottom-width: 2px; border-style: solid none solid none; }' +
        '           tr.total td.row-label { font-weight: bold ;}' +
        '           h1 { font-size: 100%; font-weight: bold; color: black; }' +
        '           h2 { font-size: 100%; font-weight: bold; margin: 1em 0 1em 0; }' +
        '           h2.middle { text-align: center; }' +
        '           h3 { font-size: 100%; font-weight: bold; margin: 0.5em 0 }' +
        '           span.officername {font-weight: bold;}' +
        '           #balancesheet tr.heading td { padding-top: 1em;}' +
        '           #balancesheet tr.separator th { margin-top: 0.8em; }' +
        '           #statements ol {list-style-type: lower-alpha; padding-left: 20px;}' +
        '           th.normal{font-weight: normal;}' +
        '           #approval {margin-top: 1cm; margin-left: 10%; width: 80%;}' +
        '           @media screen, projection, tv {' +
        '               body { margin: 2% 4% 2% 4%; background-color: gray;}' +
        '               div.accountspage { background-color: white; padding: 2em 2em 2em 2em; width: 20cm; height: 29cm;} /*A4 Dimensions */' +
        '               div.titlepage {padding: 5em 2em 2em 2em; margin: 2em 0 2em 0; }' +
        '           }' +
        '       </style>' +
        '       <title>Dormant Company Accounts - {{COMPANY_NAME}}</title>' +
        '    </head>' +
        '    <body>' +
        '        <div style="display: none">' +
        '            <ix:header>' +
        '                <ix:hidden>' +
        '                    <ix:nonNumeric contextRef="Director1_CY" name="core:DirectorSigningFinancialStatements"/>' +
        '                    <ix:nonNumeric contextRef="dcur3" name="uk-bus:EntityDormantTruefalse">true</ix:nonNumeric>' +
        '                    <ix:nonNumeric contextRef="icur4" name="uk-bus:StartDateForPeriodCoveredByReport">{{START_DATE_F1}}</ix:nonNumeric>' +
        '                    <ix:nonNumeric contextRef="icur4" name="uk-bus:EndDateForPeriodCoveredByReport">{{END_DATE_F1}}</ix:nonNumeric>' +
        '                    <ix:nonNumeric contextRef="dcur5" name="uk-bus:EntityTradingStatus"/>' +
        '                    <ix:nonNumeric contextRef="AccountingStandards_CY" name="uk-bus:AccountingStandardsApplied"/>' +
        '                    <ix:nonNumeric contextRef="dcur7" name="uk-bus:AccountsTypeFullOrAbbreviated"/>' +
        '                    <ix:nonNumeric contextRef="dcur8" name="uk-bus:AccountsStatusAuditedOrUnaudited"/>' +
        '                </ix:hidden>' +
        '                <ix:references>' +
        '                    <link:schemaRef' +
        '                                    xlink:href="https://xbrl.frc.org.uk/FRS-102/2021-01-01/FRS-102-2021-01-01.xsd"' +
        '                                    xlink:type="simple"/>' +
        '                </ix:references>' +
        '                <ix:resources>' +
        '                    <xbrli:context id="Director1_CY">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                            <xbrli:segment>' +
        '                                <xbrldi:explicitMember dimension="uk-bus:EntityOfficersDimension">uk-bus:Director1</xbrldi:explicitMember>' +
        '                            </xbrli:segment>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:startDate>{{START_DATE_F1}}</xbrli:startDate>' +
        '                            <xbrli:endDate>{{END_DATE_F1}}</xbrli:endDate>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:context id="dcur3">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:startDate>{{START_DATE_F1}}</xbrli:startDate>' +
        '                            <xbrli:endDate>{{END_DATE_F1}}</xbrli:endDate>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:context id="icur4">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:instant>{{END_DATE_F1}}</xbrli:instant>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:context id="dcur5">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                            <xbrli:segment>' +
        '                                <xbrldi:explicitMember dimension="uk-bus:EntityTradingStatusDimension">uk-bus:EntityHasNeverTraded</xbrldi:explicitMember>' +
        '                            </xbrli:segment>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:startDate>{{START_DATE_F1}}</xbrli:startDate>' +
        '                            <xbrli:endDate>{{END_DATE_F1}}</xbrli:endDate>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:context id="AccountingStandards_CY">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                            <xbrli:segment>' +
        '                                <xbrldi:explicitMember dimension="uk-bus:AccountingStandardsDimension">uk-bus:Micro-entities</xbrldi:explicitMember>' +
        '                            </xbrli:segment>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:startDate>{{START_DATE_F1}}</xbrli:startDate>' +
        '                            <xbrli:endDate>{{END_DATE_F1}}</xbrli:endDate>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:context id="dcur7">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                            <xbrli:segment>' +
        '                                <xbrldi:explicitMember dimension="uk-bus:AccountsTypeDimension">uk-bus:FullAccounts</xbrldi:explicitMember>' +
        '                            </xbrli:segment>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:startDate>{{START_DATE_F1}}</xbrli:startDate>' +
        '                            <xbrli:endDate>{{END_DATE_F1}}</xbrli:endDate>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:context id="dcur8">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                            <xbrli:segment>' +
        '                                <xbrldi:explicitMember dimension="uk-bus:AccountsStatusDimension">uk-bus:AuditExempt-NoAccountantsReport</xbrldi:explicitMember>' +
        '                            </xbrli:segment>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:startDate>{{START_DATE_F1}}</xbrli:startDate>' +
        '                            <xbrli:endDate>{{END_DATE_F1}}</xbrli:endDate>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:context id="iprev9">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:instant>{{LAST_END_DATE_F1}}</xbrli:instant>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:context id="dcur-shareclass-ordinary-1">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                            <xbrli:segment>' +
        '                                <xbrldi:explicitMember dimension="uk-bus:EntityShareClassesDimension">uk-bus:OrdinaryShareClass1</xbrldi:explicitMember>' +
        '                            </xbrli:segment>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:startDate>{{START_DATE_F1}}</xbrli:startDate>' +
        '                            <xbrli:endDate>{{END_DATE_F1}}</xbrli:endDate>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:context id="dcur-shareclass-ordinary-2">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                            <xbrli:segment>' +
        '                                <xbrldi:explicitMember dimension="uk-bus:EntityShareClassesDimension">uk-bus:OrdinaryShareClass2</xbrldi:explicitMember>' +
        '                            </xbrli:segment>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:startDate>{{START_DATE_F1}}</xbrli:startDate>' +
        '                            <xbrli:endDate>{{END_DATE_F1}}</xbrli:endDate>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:context id="dcur-shareclass-ordinary-3">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                            <xbrli:segment>' +
        '                                <xbrldi:explicitMember dimension="uk-bus:EntityShareClassesDimension">uk-bus:OrdinaryShareClass3</xbrldi:explicitMember>' +
        '                            </xbrli:segment>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:startDate>{{START_DATE_F1}}</xbrli:startDate>' +
        '                            <xbrli:endDate>{{END_DATE_F1}}</xbrli:endDate>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:context id="dcur-shareclass-preference-1">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                            <xbrli:segment>' +
        '                                <xbrldi:explicitMember dimension="uk-bus:EntityShareClassesDimension">uk-bus:PreferenceShareClass1</xbrldi:explicitMember>' +
        '                            </xbrli:segment>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:startDate>{{START_DATE_F1}}</xbrli:startDate>' +
        '                            <xbrli:endDate>{{END_DATE_F1}}</xbrli:endDate>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:context id="dcur-shareclass-preference-2">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                            <xbrli:segment>' +
        '                                <xbrldi:explicitMember dimension="uk-bus:EntityShareClassesDimension">uk-bus:PreferenceShareClass2</xbrldi:explicitMember>' +
        '                            </xbrli:segment>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:startDate>{{START_DATE_F1}}</xbrli:startDate>' +
        '                            <xbrli:endDate>{{END_DATE_F1}}</xbrli:endDate>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:context id="dcur-shareclass-preference-3">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                            <xbrli:segment>' +
        '                                <xbrldi:explicitMember dimension="uk-bus:EntityShareClassesDimension">uk-bus:PreferenceShareClass3</xbrldi:explicitMember>' +
        '                            </xbrli:segment>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:startDate>{{START_DATE_F1}}</xbrli:startDate>' +
        '                            <xbrli:endDate>{{END_DATE_F1}}</xbrli:endDate>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:context id="icur13">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                            <xbrli:segment>' +
        '                                <xbrldi:explicitMember dimension="core:EquityClassesDimension">core:ShareCapitalPreferenceShares</xbrldi:explicitMember>' +
        '                            </xbrli:segment>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:instant>{{END_DATE_F1}}</xbrli:instant>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:context id="iprev14">' +
        '                        <xbrli:entity>' +
        '                            <xbrli:identifier scheme="http://www.companieshouse.gov.uk/">{{COMPANY_NUMBER}}</xbrli:identifier>' +
        '                            <xbrli:segment>' +
        '                                <xbrldi:explicitMember dimension="core:EquityClassesDimension">core:ShareCapitalPreferenceShares</xbrldi:explicitMember>' +
        '                            </xbrli:segment>' +
        '                        </xbrli:entity>' +
        '                        <xbrli:period>' +
        '                            <xbrli:instant>{{LAST_END_DATE_F1}}</xbrli:instant>' +
        '                        </xbrli:period>' +
        '                    </xbrli:context>' +
        '                    <xbrli:unit id="GBP">' +
        '                        <xbrli:measure>iso4217:GBP</xbrli:measure>' +
        '                    </xbrli:unit>' +
        '                    <xbrli:unit id="shares">' +
        '                        <xbrli:measure>xbrli:shares</xbrli:measure>' +
        '                    </xbrli:unit>' +
        '                </ix:resources>' +
        '            </ix:header>' +
        '        </div>' +
        '' +
        '        <div class="titlepage accountspage pagebreak title">' +
        '            <div class="DCAtitleHeading">' +
        '                <p>Registered Number' +
        '                    <ix:nonNumeric contextRef="dcur3" name="uk-bus:UKCompaniesHouseRegisteredNumber">{{COMPANY_NUMBER}}</ix:nonNumeric>' +
        '                </p>' +
        '                <p>' +
        '                    <ix:nonNumeric contextRef="dcur3" name="uk-bus:EntityCurrentLegalOrRegisteredName">{{COMPANY_NAME}}</ix:nonNumeric>' +
        '                </p>' +
        '                <p>Dormant Accounts</p>' +
        '                <p>{{END_DATE_F2}}</p>' +
        '            </div>' +
        '            <p class="dottedLine"/>' +
        '        </div>' +
        '' +
        '        <div class="accountspage">' +
        '            <div class="accountsheader">' +
        '                <h2 class="print">' +
        '                    <span class="left">{{COMPANY_NAME}}</span>' +
        '                    <span class="right">Registered Number {{COMPANY_NUMBER}}</span>' +
        '                </h2>' +
        '                <p class="print spacer"/>' +
        '            </div>' +
        '' +
        '            <div id="balancesheet">' +
        '                <h2 class="print middle">Balance Sheet as at ' +
        '                    <ix:nonNumeric contextRef="icur4" format="ixt2:datedaymonthyearen" name="uk-bus:BalanceSheetDate">{{END_DATE_F2}}</ix:nonNumeric>' +
        '                </h2>' +
        '                <p class="print dottedLine"/>' +
        '                <table>' +
        '                    <tr>' +
        '                        <th/>' +
        '                        <th class="column-1 figure" id="currentYear">{{CURRENT_YEAR}}</th>' +
        '                        <!-- delete_if_not_previous_year-->' +
        '                        <th class="column-1 figure" id="previousYear">{{PREVIOUS_YEAR}}</th>' +
        '                        <!-- end_delete_if_not_previous_year-->' +
        '                    </tr>' +
        '                    <tr>' +
        '                        <th/>' +
        '                        <th class="figure" id="currentCurrencySymbol">£</th>' +
        '                        <!-- delete_if_not_previous_year-->' +
        '                        <th class="figure" id="previousCurrencySymbol">£</th>' +
        '                        <!-- end_delete_if_not_previous_year-->' +
        '                    </tr>' +
        '' +
        '                    <tr>' +
        '                        <td class="row-label">Current assets:</td>' +
        '                        <td class="number figure">' +
        '                            <div>' +
        '                                {{CurrentAssets_CURRENT_P1}}<ix:nonFraction contextRef="icur4" decimals="0" format="ixt2:numdotdecimal" name="core:CurrentAssets" {{CurrentAssetsSign_CURRENT}} unitRef="GBP">{{CurrentAssets_CURRENT}}</ix:nonFraction>{{CurrentAssets_CURRENT_P2}}' +
        '                            </div>' +
        '                        </td>' +
        '                        <!-- delete_if_not_previous_year-->' +
        '                        <td class="number figure">' +
        '                            <div>' +
        '                                {{CurrentAssets_PREVIOUS_P1}}<ix:nonFraction contextRef="iprev9" decimals="0" format="ixt2:numdotdecimal" name="core:CurrentAssets" {{CurrentAssetsSign_PREVIOUS}} unitRef="GBP">{{CurrentAssets_PREVIOUS}}</ix:nonFraction>{{CurrentAssets_PREVIOUS_P2}}' +
        '                            </div>' +
        '                        </td>' +
        '                        <!-- end_delete_if_not_previous_year-->' +
        '                    </tr>' +
        '' +
        '                    <tr class="separator total">' +
        '                        <td class="row-label">Net current assets (liabilities):</td>' +
        '                        <td class="number figure">' +
        '                            <div>' +
        '                                {{NetCurrentAssetsLiabilities_CURRENT_P1}}<ix:nonFraction contextRef="icur4" decimals="0" format="ixt2:numdotdecimal" name="core:NetCurrentAssetsLiabilities" {{NetCurrentAssetsLiabilitiesSign_CURRENT}} unitRef="GBP">{{NetCurrentAssetsLiabilities_CURRENT}}</ix:nonFraction>{{NetCurrentAssetsLiabilities_CURRENT_P2}}' +
        '                            </div>' +
        '                        </td>' +
        '                        <!-- delete_if_not_previous_year-->' +
        '                        <td class="number figure">' +
        '                            <div>' +
        '                                {{NetCurrentAssetsLiabilities_PREVIOUS_P1}}<ix:nonFraction contextRef="iprev9" decimals="0" format="ixt2:numdotdecimal" name="core:NetCurrentAssetsLiabilities" {{NetCurrentAssetsLiabilitiesSign_PREVIOUS}} unitRef="GBP">{{NetCurrentAssetsLiabilities_PREVIOUS}}</ix:nonFraction>{{NetCurrentAssetsLiabilities_PREVIOUS_P2}}' +
        '                            </div>' +
        '                        </td>' +
        '                        <!-- end_delete_if_not_previous_year-->' +
        '                    </tr>' +
        '' +
        '                    <tr>' +
        '                        <td class="row-label">Total assets less current liabilities:</td>' +
        '                        <td class="number figure">' +
        '                            <div>' +
        '                                {{TotalAssetsLessCurrentLiabilities_CURRENT_P1}}<ix:nonFraction contextRef="icur4" decimals="0" format="ixt2:numdotdecimal" name="core:TotalAssetsLessCurrentLiabilities" {{TotalAssetsLessCurrentLiabilitiesSign_CURRENT}} unitRef="GBP">{{TotalAssetsLessCurrentLiabilities_CURRENT}}</ix:nonFraction>{{TotalAssetsLessCurrentLiabilities_CURRENT_P2}}' +
        '                            </div>' +
        '                        </td>' +
        '                        <!-- delete_if_not_previous_year-->' +
        '                        <td class="number figure">' +
        '                            <div>' +
        '                                {{TotalAssetsLessCurrentLiabilities_PREVIOUS_P1}}<ix:nonFraction contextRef="iprev9" decimals="0" format="ixt2:numdotdecimal" name="core:TotalAssetsLessCurrentLiabilities" {{TotalAssetsLessCurrentLiabilitiesSign_PREVIOUS}} unitRef="GBP">{{TotalAssetsLessCurrentLiabilities_PREVIOUS}}</ix:nonFraction>{{TotalAssetsLessCurrentLiabilities_PREVIOUS_P2}}' +
        '                            </div>' +
        '                        </td>' +
        '                        <!-- end_delete_if_not_previous_year-->' +
        '                    </tr>' +
        '' +
        '                    <tr class="separator total">' +
        '                        <td class="row-label">Total net assets (liabilities):</td>' +
        '                        <td class="number total figure">' +
        '                            <div>' +
        '                                {{NetAssetsLiabilities_CURRENT_P1}}<ix:nonFraction contextRef="icur4" decimals="0" format="ixt2:numdotdecimal" name="core:NetAssetsLiabilities" {{NetAssetsLiabilitiesSign_CURRENT}} unitRef="GBP">{{NetAssetsLiabilities_CURRENT}}</ix:nonFraction>{{NetAssetsLiabilities_CURRENT_P2}}' +
        '                            </div>' +
        '                        </td>' +
        '                        <!-- delete_if_not_previous_year-->' +
        '                        <td class="number total figure">' +
        '                            <div>' +
        '                                {{NetAssetsLiabilities_PREVIOUS_P1}}<ix:nonFraction contextRef="iprev9" decimals="0" format="ixt2:numdotdecimal" name="core:NetAssetsLiabilities" {{NetAssetsLiabilitiesSign_PREVIOUS}} unitRef="GBP">{{NetAssetsLiabilities_PREVIOUS}}</ix:nonFraction>{{NetAssetsLiabilities_PREVIOUS_P2}}' +
        '                            </div>' +
        '                        </td>' +
        '                        <!-- end_delete_if_not_previous_year-->' +
        '                    </tr>' +
        '' +
        '                    <tr class="separator total">' +
        '                        <td class="row-label">Capital and reserves:</td>' +
        '                        <td class="number total figure">' +
        '                            <div>' +
        '                                {{Equity_CURRENT_P1}}<ix:nonFraction contextRef="icur4" decimals="0" format="ixt2:numdotdecimal" name="core:Equity" {{EquitySign_CURRENT}} unitRef="GBP">{{Equity_CURRENT}}</ix:nonFraction>{{Equity_CURRENT_P2}}' +
        '                            </div>' +
        '                        </td>' +
        '                        <!-- delete_if_not_previous_year-->' +
        '                        <td class="number total figure">' +
        '                            <div>' +
        '                                {{Equity_PREVIOUS_P1}}<ix:nonFraction contextRef="iprev9" decimals="0" format="ixt2:numdotdecimal" name="core:Equity" {{EquitySign_PREVIOUS}} unitRef="GBP">{{Equity_PREVIOUS}}</ix:nonFraction>{{Equity_PREVIOUS_P2}}' +
        '                            </div>' +
        '                        </td>' +
        '                        <!-- end_delete_if_not_previous_year-->' +
        '                    </tr>' +
        '                </table>' +
        '            </div>' +
        '' +
        '            <div id="statements">' +
        '                <h3>STATEMENTS</h3>' +
        '                <ol class="lower-alpha">' +
        '                    <li id="dormantCompany-statement">' +
        '                        <ix:nonNumeric contextRef="dcur3" name="direp:StatementThatCompanyEntitledToExemptionFromAuditUnderSection480CompaniesAct2006RelatingToDormantCompanies">For the year ending {{END_DATE_F2}} the company was entitled to exemption under section 480 of the Companies Act 2006 relating to dormant companies.</ix:nonNumeric>' +
        '                    </li>' +
        '                    <li id="auditExempt-statement">' +
        '                        <ix:nonNumeric contextRef="dcur3" name="direp:StatementThatMembersHaveNotRequiredCompanyToObtainAnAudit">The members have not required the company to obtain an audit in accordance with section 476 of the Companies Act 2006.</ix:nonNumeric>' +
        '                    </li>' +
        '                    <li id="directorsResponsibilities-statement">' +
        '                        <ix:nonNumeric contextRef="dcur3" name="direp:StatementThatDirectorsAcknowledgeTheirResponsibilitiesUnderCompaniesAct">The directors acknowledge their responsibilities for complying with the requirements of the Act with respect to accounting records and the preparation of accounts.</ix:nonNumeric>' +
        '                    </li>' +
        '                    <li id="smallCompany-statement">' +
        '                        <ix:nonNumeric contextRef="dcur3" name="direp:StatementThatAccountsHaveBeenPreparedInAccordanceWithProvisionsSmallCompaniesRegime"> These accounts have been prepared in accordance with the provisions applicable to companies subject to the small companies regime.</ix:nonNumeric>' +
        '                    </li>' +
        '                </ol>' +
        '            </div>' +
        '' +
        '            <div id="approval">' +
        '                <p>Approved by the Board on' +
        '                    <ix:nonNumeric contextRef="icur4" format="ixt2:datedaymonthyearen" name="core:DateAuthorisationFinancialStatementsForIssue">{{DATE_SIGNED_F2}}</ix:nonNumeric>' +
        '                </p>' +
        '                <p>And signed on their behalf by:<br/>' +
        '                    <span class="officername" id="officername_0">' +
        '                        <ix:nonNumeric contextRef="Director1_CY" name="uk-bus:NameEntityOfficer">{{DIRECTOR_NAME}}</ix:nonNumeric>, Director' +
        '                    </span>' +
        '                    <br/>' +
        '                </p>' +
        '            </div>' +
        '' +
        '            <div id="notes">' +
        '                <h2 class="print left">Notes</h2>' +
        '                <p class="print dottedLine"/>' +
        '                <table>' +
        '                    <tr>' +
        '                        <th/>' +
        '                        <th class="column-1 figure" id="currentYear_employees">{{CURRENT_YEAR}}</th>' +
        '                        <!-- delete_if_not_previous_year-->' +
        '                        <th class="column-1 figure" id="previousYear_employees">{{PREVIOUS_YEAR}}</th>' +
        '                        <!-- end_delete_if_not_previous_year-->' +
        '                    </tr>' +
        '                    <tr>' +
        '                        <td>Average number of employees during the period</td>' +
        '                        <td class="column-1 figure" id="currentYear_employees_value">0</td>' +
        '                        <!-- delete_if_not_previous_year-->' +
        '                        <td class="column-1 figure" id="previousYear_employees_value">0</td>' +
        '                        <!-- end_delete_if_not_previous_year-->' +
        '                    </tr>' +
        '                </table>' +
        '           </div>' +
        '        </div>' +
        '    </body>' +
        '</html>',
};

export default balanceSheetTemplate;
