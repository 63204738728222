import React, { FC, useMemo } from 'react';
import { Button, LinearProgress, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useStyles } from './styles';
import Company from '../../../models/Company';
import CompanyFinancialDataModel from '../../../models/CompanyFinancialData.model';

interface Props {
    company?: Company;
    financialData?: CompanyFinancialDataModel;
}

const BalanceSheet: FC<Props> = ({ company, financialData }) => {
    const classes = useStyles();

    const formatValue = (value: number | undefined): string => {
        if (!value) return '-';

        return (value < 0 ? `(${(value * -1).toString()})` : value.toString()).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ',',
        );
    };

    const hasPreviousYear = useMemo(
        (): boolean => !!financialData?.hasPreviousYear,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [financialData?.hasPreviousYear],
    );

    return (
        <>
            <p className={classes.companyName}>
                {company?.title}

                {financialData && financialData.endDate && (
                    <span className={classes.balanceSheetTitle}>
                        Balance Sheet as at {dayjs(financialData?.endDate).format('DD MMMM YYYY')}
                    </span>
                )}
            </p>

            <div className={classes.sheetContainer}>
                {!company?.financialDataLoaded && <LinearProgress />}

                <Table>
                    <TableBody>
                        <TableRow className={clsx(classes.sheetHeaderBlue)}>
                            <TableCell className={classes.tableCell} />
                            <TableCell
                                className={clsx(
                                    classes.tableCell,
                                    classes.sheetHeaderText,
                                    classes.sheetHeaderTextBlue,
                                    classes.sheetHeaderTextLarger,
                                )}
                                align="right"
                            >
                                <Button
                                    variant="text"
                                    onClick={() => {
                                        const wnd = window.open('about:blank', '');
                                        if (wnd) wnd.document.write(financialData?.newDocument || '');
                                    }}
                                    className={clsx(
                                        classes.sheetHeaderText,
                                        classes.sheetHeaderTextBlue,
                                        classes.sheetHeaderTextLarger,
                                    )}
                                >
                                    {financialData?.currentYear.year}
                                </Button>
                            </TableCell>

                            {hasPreviousYear && (
                                <TableCell
                                    className={clsx(
                                        classes.tableCell,
                                        classes.sheetHeaderText,
                                        classes.sheetHeaderTextBlue,
                                        classes.sheetHeaderTextLarger,
                                    )}
                                    align="right"
                                >
                                    <Button
                                        variant="text"
                                        onClick={() => {
                                            const wnd = window.open('about:blank', '');
                                            if (wnd) wnd.document.write(financialData?.oldDocument || '');
                                        }}
                                        className={clsx(
                                            classes.sheetHeaderText,
                                            classes.sheetHeaderTextBlue,
                                            classes.sheetHeaderTextLarger,
                                        )}
                                    >
                                        {dayjs(financialData?.currentYear.year)
                                            .add(-1, 'years')
                                            .format('YYYY')}
                                    </Button>
                                </TableCell>
                            )}
                        </TableRow>

                        <TableRow>
                            <TableCell
                                className={clsx(
                                    classes.tableCell,
                                    classes.tableCellHeader,
                                    classes.tableRowSpaced,
                                )}
                            >
                                ASSETS
                            </TableCell>
                            <TableCell className={clsx(classes.tableCell, classes.tableRowSpaced)} />
                        </TableRow>

                        <TableRow>
                            <TableCell className={clsx(classes.tableCell, classes.tableCellCompact)}>
                                Fixed Assets
                            </TableCell>
                            <TableCell
                                className={clsx(
                                    classes.tableCell,
                                    classes.tableCellBolder,
                                    classes.tableCellCompact,
                                )}
                                align="right"
                            >
                                {formatValue(financialData?.currentYear.fixedAssets.value)}
                            </TableCell>

                            {hasPreviousYear && (
                                <TableCell
                                    className={clsx(
                                        classes.tableCell,
                                        classes.tableCellBolder,
                                        classes.tableCellCompact,
                                    )}
                                    align="right"
                                >
                                    {formatValue(financialData?.currentYear.fixedAssets.value)}
                                </TableCell>
                            )}
                        </TableRow>

                        <TableRow>
                            <TableCell className={clsx(classes.tableCell)}>Current Assets</TableCell>
                            <TableCell
                                className={clsx(
                                    classes.tableCell,
                                    classes.tableCellBolder,
                                    classes.tableCellCompact,
                                )}
                                align="right"
                            >
                                {formatValue(financialData?.currentYear.currentAssets.value)}
                            </TableCell>

                            {hasPreviousYear && (
                                <TableCell
                                    className={clsx(
                                        classes.tableCell,
                                        classes.tableCellBolder,
                                        classes.tableCellCompact,
                                    )}
                                    align="right"
                                >
                                    {formatValue(financialData?.currentYear.currentAssets.value)}
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow>
                            <TableCell
                                className={clsx(
                                    classes.tableCell,
                                    classes.tableCellHeader,
                                    classes.tableRowSpacedLess,
                                )}
                            >
                                LIABILITIES
                            </TableCell>
                            <TableCell className={clsx(classes.tableCell, classes.tableRowSpaced)} />
                        </TableRow>

                        <TableRow>
                            <TableCell className={clsx(classes.tableCell, classes.tableCellCompact)}>
                                Current Creditors
                            </TableCell>
                            <TableCell
                                className={clsx(
                                    classes.tableCell,
                                    classes.tableCellBolder,
                                    classes.tableCellCompact,
                                )}
                                align="right"
                            >
                                {formatValue(
                                    financialData?.currentYear.currentCreditors.fields[
                                        'accruals and deferred income'
                                    ]
                                        ? financialData?.currentYear.currentCreditors.value +
                                              financialData?.currentYear.currentCreditors.fields[
                                                  'accruals and deferred income'
                                              ]
                                        : financialData?.currentYear.currentCreditors.value,
                                )}
                            </TableCell>

                            {hasPreviousYear && (
                                <TableCell
                                    className={clsx(
                                        classes.tableCell,
                                        classes.tableCellBolder,
                                        classes.tableCellCompact,
                                    )}
                                    align="right"
                                >
                                    {formatValue(
                                        financialData?.currentYear.currentCreditors.fields[
                                            'accruals and deferred income'
                                        ]
                                            ? financialData?.currentYear.currentCreditors.value +
                                                  financialData?.currentYear.currentCreditors.fields[
                                                      'accruals and deferred income'
                                                  ]
                                            : financialData?.currentYear.currentCreditors.value,
                                    )}
                                </TableCell>
                            )}
                        </TableRow>

                        <TableRow>
                            <TableCell className={clsx(classes.tableCell, classes.tableRowSpacedBottom)}>
                                Non-Current Creditors
                            </TableCell>
                            <TableCell
                                className={clsx(
                                    classes.tableCell,
                                    classes.tableCellBolder,
                                    classes.tableRowSpacedBottom,
                                )}
                                align="right"
                            >
                                {formatValue(financialData?.currentYear.nonCurrentCreditors.value)}
                            </TableCell>

                            {hasPreviousYear && (
                                <TableCell
                                    className={clsx(
                                        classes.tableCell,
                                        classes.tableCellBolder,
                                        classes.tableRowSpacedBottom,
                                    )}
                                    align="right"
                                >
                                    {formatValue(financialData?.currentYear.nonCurrentCreditors.value)}
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow className={clsx(classes.sheetHeaderBlue)}>
                            <TableCell
                                className={clsx(
                                    classes.tableCell,
                                    classes.sheetHeaderText,
                                    classes.sheetHeaderTextBlue,
                                )}
                            >
                                Net Assets
                            </TableCell>

                            <TableCell
                                className={clsx(
                                    classes.tableCell,
                                    classes.sheetHeaderText,
                                    classes.sheetHeaderTextBlue,
                                )}
                                align="right"
                            >
                                {formatValue(financialData?.currentYear.netAssets.value)}
                            </TableCell>

                            {hasPreviousYear && (
                                <TableCell
                                    className={clsx(
                                        classes.tableCell,
                                        classes.sheetHeaderText,
                                        classes.sheetHeaderTextBlue,
                                    )}
                                    align="right"
                                >
                                    {formatValue(financialData?.currentYear.netAssets.value)}
                                </TableCell>
                            )}
                        </TableRow>

                        <TableRow>
                            <TableCell
                                className={clsx(
                                    classes.tableCell,
                                    classes.tableCellHeader,
                                    classes.tableRowSpaced,
                                )}
                            >
                                EQUITY
                            </TableCell>
                            <TableCell className={clsx(classes.tableCell, classes.tableRowSpaced)} />
                        </TableRow>

                        <TableRow className={clsx(classes.sheetHeaderBlue)}>
                            <TableCell
                                className={clsx(
                                    classes.tableCell,
                                    classes.sheetHeaderText,
                                    classes.sheetHeaderTextBlue,
                                )}
                            >
                                Capital and reserves
                            </TableCell>
                            <TableCell
                                className={clsx(
                                    classes.tableCell,
                                    classes.sheetHeaderText,
                                    classes.sheetHeaderTextBlue,
                                )}
                                align="right"
                            >
                                {formatValue(financialData?.currentYear.capitalAndReserves.value)}
                            </TableCell>

                            {hasPreviousYear && (
                                <TableCell
                                    className={clsx(
                                        classes.tableCell,
                                        classes.sheetHeaderText,
                                        classes.sheetHeaderTextBlue,
                                    )}
                                    align="right"
                                >
                                    {formatValue(financialData?.currentYear.capitalAndReserves.value)}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        </>
    );
};

BalanceSheet.defaultProps = {
    company: undefined,
    financialData: undefined,
};

export default BalanceSheet;
