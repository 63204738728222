import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    input: {
        height: 55,
        boxSizing: 'border-box',
        fontSize: 18,
        color: '#15416D',
        lineHeight: '16px',
        fontWeight: 400,
        '&::placeholder': {
            color: '#001733',
            fontSize: 18,
            lineHeight: '16px',
            letterSpacing: '0.1px',
            fontWeight: 400,
        },
    },
    error: {},
    focused: {},
    notchedOutline: {
        border: '0.5px solid rgba(0, 0, 0, 0.2)',
    },
    completed: {
        border: '1px solid #1FC29B',
    },
    root: {
        backgroundColor: '#FFFFFF',
        '&$error $notchedOutline': {
            border: '1px solid #E44447',
        },
        '&$focused $notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.87)',
        },
        '&:hover $notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.87)',
        },
    },
    rootTextField: {
        marginTop: '0.25rem',
        '& > p': {
            marginLeft: 0,
            marginRight: 0,
            color: '#E44447 !important',
            fontWeight: 600,
            fontSize: 12,
            lineHeight: '16.8px',
            position: 'absolute',
            bottom: -20,
        },
    },
    statusIcon: {
        position: 'absolute',
        right: -27,
        top: 22,
    },
    errorIcon: {
        position: 'absolute',
        right: -22,
        top: 22,
    },
    container: {
        position: 'relative',
    },
});
