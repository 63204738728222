import OpenInNew from '@material-ui/icons/OpenInNew';
import { FC } from 'react';
import { useStyles } from '../styles';

const WhatIsDormant: FC = () => {
    const classes = useStyles();

    return (
        <>
            {`Your company is dormant according to `}
            <a
                href="https://www.gov.uk/dormant-company/dormant-for-companies-house"
                target="_blank"
                rel="noreferrer"
            >
                Companies House <OpenInNew className={classes.newLinkIcon} />
            </a>
            {' if it had no transactions in the financial year apart from:'}
            <ul>
                <li>filing fees paid to Companies House</li>
                <li>penalties for late filing of accounts</li>
                <li>money paid for shares when the company was set up</li>
            </ul>
            <p>
                If your company had any transactions other than the 3 listed above, then it was not dormant
                and you cannot use this service to file dormant company accounts.
            </p>
            <p>
                Dormant accounts are a much more simplified version of the full set of accounts, with often
                only shares and cash in hand/bank on the balance sheet, but it can be more if the company
                traded previously.
            </p>
            <p>
                Companies House and HMRC have slightly different definitions of ‘dormant’, but effectively if
                your company meets the definition for one it meets it for both. You can check on{' '}
                <a
                    href="https://www.gov.uk/dormant-company/dormant-for-corporation-tax"
                    target="_blank"
                    rel="noreferrer"
                >
                    HMRC’s website <OpenInNew className={classes.newLinkIcon} />
                </a>{' '}
                for ensuring you don’t need to file a Corporation Tax return.
            </p>
        </>
    );
};

export default WhatIsDormant;
