export const ROUTES = {
    PUBLIC: {
        LANDING: '/',
        LOGIN: '/login',
        FINANCIAL: '/financial_service',
        PAYMENTS: '/payments',
        AUTHENTICATION: '/authentication',
        PAYMENT_CONFIRMATION: '/payment_confirmation',
        CONTACT: '/contact',
        PRIVACY_POLICY: '/privacy-policy',
    },
    AUTHORIZED: {
        EXAMPLE: '/auth',
    },
};

export const STEPPER_CONFIG = [
    {
        path: ROUTES.PUBLIC.FINANCIAL,
        step: 1,
        prevPath: ROUTES.PUBLIC.LANDING,
        prevName: 'Company Lookup',
    },
    {
        path: ROUTES.PUBLIC.AUTHENTICATION,
        step: 2,
        prevPath: ROUTES.PUBLIC.FINANCIAL,
        prevName: 'Confirmation',
    },
    {
        path: ROUTES.PUBLIC.PAYMENTS,
        step: 3,
        prevPath: ROUTES.PUBLIC.AUTHENTICATION,
        prevName: 'Authentication',
    },
    {
        path: ROUTES.PUBLIC.PAYMENT_CONFIRMATION,
        step: 4,
        prevPath: ROUTES.PUBLIC.LANDING,
        prevName: 'Start Again',
    },
];
