import { StepConnector, withStyles } from '@material-ui/core';

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#006FF1',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#006FF1',
        },
    },
    line: {
        marginLeft: -11,
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
        width: 'calc(100% + 22px)',
    },
})(StepConnector);

export default QontoConnector;
