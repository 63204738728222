import React, { FC } from 'react';
import CheckIcon from '../../../assets/images/check-icon.png';
import { useStyles } from './styles';

interface Props {
    title: string;
    description: string;
}

const ProductAdvantage: FC<Props> = ({ title, description }) => {
    const classes = useStyles();

    return (
        <div className={classes.advantageContainer}>
            <img src={CheckIcon} alt="Check" className={classes.advantageImage} />
            <div className={classes.advantageTextContainer}>
                <p className={classes.advantageTitle}>{title}</p>
                <span className={classes.advantageDescription}>{description}</span>
            </div>
        </div>
    );
};

export default ProductAdvantage;
