import React, { FC } from 'react';
import { Hidden, Step, StepLabel, Stepper } from '@material-ui/core';
import clsx from 'clsx';
import QontoConnector from './QontoConnector/QontoConnector';
import { useStyles } from './styles';
import StepIcon from './StepIcon';

interface Props {
    activeStep: number;
}

const CustomStepper: FC<Props> = ({ activeStep }) => {
    const classes = useStyles();
    return (
        <Stepper
            activeStep={activeStep}
            className={classes.container}
            alternativeLabel
            connector={<QontoConnector />}
        >
            {['Company Lookup', 'Confirmation', 'Authentication', 'Payment Details'].map(
                (label: string, key: number) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={StepIcon}>
                            <Hidden smDown>
                                <span
                                    className={clsx(classes.label, {
                                        [classes.activeLabel]: activeStep === key,
                                    })}
                                >
                                    {label}
                                </span>
                            </Hidden>
                        </StepLabel>
                    </Step>
                ),
            )}
        </Stepper>
    );
};

export default CustomStepper;
