import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    drawerPaper: {
        backgroundColor: '#006FF1',
        width: '100%',
    },
    menuHeader: {
        borderTop: '1px solid rgba(255, 255, 255, 0.3)',
        borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
        height: 79,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 16px',
        flexShrink: 0,
    },
    titleLink: {
        textDecoration: 'none',
    },
    menuHeaderText: {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: 1.25,
        letterSpacing: '0.01em',
        color: '#FFFFFF',
    },
    listItem: {
        minHeight: 79,
        padding: '30px 16px',
        borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexGrow: 1,
    },
    listItemText: {
        fontWeight: 600,
        fontSize: 20,
        lineHeight: 1.25,
        letterSpacing: '0.01em',
        color: '#FFFFFF',
        textDecoration: 'none',
    },
    closeButton: {},
    closeButtonIcon: {
        color: 'white',
        fontSize: 30,
    },
    button: {
        textTransform: 'none',
        padding: 0,
        margin: 0,
    },
    collapse: {
        display: 'block',
        color: 'white',
    },
    collapseContent: {
        padding: '20px 10px',
        '&  a': {
            color: 'white',
            fontWeight: 'bold',
        },
        '&  button': {
            color: 'white',
            fontWeight: 'bold',
            textDecoration: 'underline',
        },
    },
});
