import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        padding: 30,
        minHeight: 630,
        flexDirection: 'row',
        alignItems: 'center',
    },
    pageTitle: {
        color: '#001733',
        fontWeight: 700,
        fontSize: 55,
        lineHeight: '120%',
        marginTop: 0,
        marginBottom: 35,
        [theme.breakpoints.down('sm')]: {
            fontSize: 35,
        },
    },
    pageTitleBlueArea: {
        color: '#3170e9',
    },
    pageSubtitle: {
        fontSize: 20,
        lineHeight: '140%',
        marginTop: 0,
        marginBottom: 70,
        maxWidth: 540,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    subtitleUnderlined: {
        borderBottom: '2px solid #006FF1',
    },
    leftGridColumn: {
        maxWidth: 765,
    },
    rightGridColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
}));
