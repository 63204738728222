import { FC, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Button, Hidden } from '@material-ui/core';
import CustomTooltip from '../../components/CustomTooltip';
import { useStyles } from './styles';
import MenuDrawer from './MenuDrawer';
import getStaticContent from './StaticContent';
import MenuContext from '../../components/MenuContext';

const Header: FC = () => {
    const classes = useStyles();
    const { openTooltips, openTooltip, closeAllTooltips } = useContext(MenuContext);

    const tooltips = getStaticContent(() => openTooltip(0));

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.header}>
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h6" className={classes.title}>
                        <NavLink to="/" className={classes.titleLink}>
                            just <strong>dormant</strong> accounts.
                        </NavLink>
                    </Typography>

                    <Hidden smDown>
                        {tooltips.map((tooltip, key) => (
                            <div key={key}>
                                {!tooltip.path ? (
                                    <CustomTooltip
                                        key={key}
                                        interactive
                                        placement="bottom-end"
                                        arrow={false}
                                        title={tooltip.element}
                                        open={openTooltips[key]}
                                        onClose={() => closeAllTooltips()}
                                        large={tooltip.large}
                                    >
                                        <Button
                                            disableRipple
                                            className={classes.menuButton}
                                            classes={{ root: classes.menuButton, label: classes.buttonLabel }}
                                            color="inherit"
                                            onClick={() => openTooltip(key)}
                                        >
                                            {tooltip.name}
                                        </Button>
                                    </CustomTooltip>
                                ) : (
                                    <Button
                                        disableRipple
                                        className={classes.menuButton}
                                        classes={{ root: classes.menuButton, label: classes.buttonLabel }}
                                        color="inherit"
                                        component={NavLink}
                                        to={tooltip.path}
                                    >
                                        {tooltip.name}
                                    </Button>
                                )}
                            </div>
                        ))}
                    </Hidden>

                    <MenuDrawer />
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Header;
