import axios from 'axios';
import CompaniesSearchModel from '../models/CompaniesSearch.model';

export const searchCompany = async (
    query = '',
    items_per_page = 20,
    start_index = 0,
): Promise<CompaniesSearchModel> => {
    try {
        const { data } = await axios.get(
            `${process.env.REACT_APP_AWS_URL}/search-companies?query=${query}&start_index=${start_index}&items_per_page=${items_per_page}`,
        );
        return data;
    } catch (_) {
        return {
            etag: '',
            kind: '',
            items_per_page: 0,
            start_index: 0,
            total_results: 0,
            page_number: 0,
            items: [],
        };
    }
};
