import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        backgroundColor: 'transparent',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
        },
    },
    label: {
        fontSize: 12,
        lineHeight: '145%',
    },
    activeLabel: {
        fontWeight: 600,
        color: '#006FF1',
    },
}));
