import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useStyles } from './styles';
import CardDetails from '../../components/CardDetails';
import StripePowerIcon from '../../assets/images/stripe-power-icon.svg';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

const PaymentsPage: FC = () => {
    const classes = useStyles();

    const fonts = [{ cssSrc: 'https://fonts.googleapis.com/css?family=Source%20Sans%20Pro:400,600,700' }];
    return (
        <Elements stripe={stripePromise} options={{ fonts }}>
            <>
                <div className={classes.container}>
                    <Typography variant="h1" className={classes.pageTitle}>
                        Payment Details
                    </Typography>
                    <CardDetails />

                    <img
                        src={StripePowerIcon}
                        alt="Powered by stripe"
                        width="115"
                        height="25"
                        className={classes.stripeIcon}
                    />
                </div>
            </>
        </Elements>
    );
};

export default PaymentsPage;
