import { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';
import ProductAdvantage from './ProductAdvantage';
import SearchCompany from './SearchCompany';

const LandingPage: FC = () => {
    const classes = useStyles();

    const renderPageTitle = () => (
        <p className={classes.pageTitle}>
            {`File your dormant company accounts in just `}
            <span className={classes.pageTitleBlueArea}>3 easy steps.</span>
        </p>
    );

    const renderPageDescription = () => (
        <p className={classes.pageSubtitle}>
            In under 2 minutes, our easy to use service lets you file your dormant accounts to Companies House
            - and for only <strong className={classes.subtitleUnderlined}>£9.</strong>
        </p>
    );

    const renderProductAdvantages = () => (
        <>
            <ProductAdvantage
                title="It's easy"
                description="You can file your dormant company accounts with us in just 3 simple steps."
            />
            <ProductAdvantage
                title="It's fast"
                description="It takes less than 2 minutes to file your dormant accounts with our service."
            />
            <ProductAdvantage
                title="It's reliable"
                description="Filings are made with Companies House directly to remain compliant."
            />
        </>
    );

    return (
        <div className={classes.container}>
            <Grid container direction="row" alignItems="flex-start" justify="center">
                <Grid item xs={12} sm={12} md={7} lg={8} xl={8} className={classes.leftGridColumn}>
                    {renderPageTitle()}
                    {renderPageDescription()}
                    <SearchCompany />
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={4} xl={4} className={classes.rightGridColumn}>
                    {renderProductAdvantages()}
                </Grid>
            </Grid>
        </div>
    );
};

export default LandingPage;
