import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    gridContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        flexGrow: 1,
        alignItems: 'flex-start',
        marginTop: 50,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    leftColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        marginBottom: 100,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
        },
    },
    progress: {
        position: 'absolute',
        bottom: 20,
        right: 20,
    },
}));
