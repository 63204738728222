import React, { ReactElement } from 'react';
import WhatIsDormant from './WhatIsDormant';
import WhoIsFor from './WhoIsFor';
import WhatINeed from './WhatINeed';
import { ROUTES } from '../../../config/constants';
import PrivacyPolicy from './PrivacyPolicy';

const getStaticContent = (
    openTooltipOrCollapse: () => void,
): { name: string; element: ReactElement; path?: string; large?: boolean }[] => {
    return [
        {
            name: 'What is dormant?',
            element: <WhatIsDormant />,
        },
        {
            name: `Who is this for?`,
            element: <WhoIsFor openTooltipOrCollapse={openTooltipOrCollapse} />,
        },
        {
            name: 'What do I need?',
            element: <WhatINeed />,
        },
        { name: 'Policies', element: <PrivacyPolicy />, large: true },
        { name: 'Contact us', element: <div />, path: ROUTES.PUBLIC.CONTACT },
    ];
};

export default getStaticContent;
