import axios from 'axios';
import Company from '../models/Company';

function escapeXml(unsafe: string) {
    return unsafe.replace(/[<>&'"]/g, (c: string): string => {
        switch (c) {
            case '<':
                return '&lt;';
            case '>':
                return '&gt;';
            case '&':
                return '&amp;';
            case "'":
                return '&apos;';
            case '"':
                return '&quot;';
            default:
                return '';
        }
    });
}

export const submitForm = async (
    company: Company,
    paymentIntentId: string,
    customerEmail: string,
): Promise<boolean> => {
    try {
        const { data } = await axios.post(`${process.env.REACT_APP_AWS_URL}/send-document`, {
            paymentIntentId,
            customerEmail,
            chData: {
                transactionId: `ABCDEF0123456789${new Date().getTime().toString()}`,
                test: process.env.REACT_APP_CH_TEST_MODE,
                companyNumber: company.company_number,
                companyName: escapeXml(company.title),
                companyAuthCode: company.authCode,
                form: window.btoa(unescape(encodeURIComponent(company.financialData.newDocument))),
            },
        });

        return !data.includes('error');
    } catch (e) {
        return false;
    }
};
