import React, { FC, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import PaymentSummary from './PaymentSummary';
import { useStyles } from './styles';
import CompanyContext from '../../components/CompanyContext';
import { ROUTES } from '../../config/constants';

const PaymentConfirmationPage: FC = () => {
    const classes = useStyles();
    const { company } = useContext(CompanyContext);

    return (
        <div>
            <Grid container direction="row" alignItems="flex-start" justify="flex-start">
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.gridItem}>
                    <div className={classes.textContainer}>
                        <Typography variant="h1" className={classes.pageTitle}>
                            <CheckCircleIcon className={classes.icon} /> Success!
                        </Typography>

                        <Typography className={classes.transactionNumber}>
                            {`Transaction #${company.payment.transactionId}`}
                        </Typography>

                        <Typography className={classes.thankYou}>
                            Thank you for filing your dormant company accounts with Just Dormant Accounts.
                        </Typography>

                        <Typography className={classes.confirmationEmail}>
                            Your payment has been successful and your dormant company accounts have been filed
                            with Companies House. A confirmation email with your payment receipt has been sent
                            to <span className={classes.email}>{company.payment.email}.</span>
                        </Typography>
                    </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.gridItem}>
                    <PaymentSummary />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className={clsx(classes.gridItem, classes.lastGridItem)}
                >
                    <div className={classes.textContainer}>
                        <Typography>
                            If you have any questions or concerns please
                            <NavLink to={ROUTES.PUBLIC.CONTACT} className={classes.getInTouch}>
                                {' '}
                                get in touch
                            </NavLink>
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default PaymentConfirmationPage;
