import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    name: yup.string().required('Card holder name is required.'),
    email: yup
        .string()
        .email('This email is not valid. Please check your email and try again.')
        .required('Email is required.'),
});

export interface CardDetailsForm {
    name: string;
    email: string;
}

export const initialValues: CardDetailsForm = { name: '', email: '' };
