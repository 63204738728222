import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    form: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: 32,
        maxWidth: 486,
        padding: 16,
        width: '100%',
        margin: '0 auto',
    },
    nextButton: {
        display: 'block',
        marginLeft: 16,
        padding: '15px 32px',
        borderRadius: 24,
    },
    inputLabel: {
        color: '#006FF1',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '19.6px',
        fontSpacing: '2%',
        marginBottom: 6,
    },
    element: {
        padding: '18px 14px',
        height: 55,
        border: '0.5px solid rgba(0, 0, 0, 0.2)',
        borderRadius: 4,
        margin: '8px 0 4px',
        backgroundColor: '#FFFFFF',
        '&:hover': {
            border: '0.5px solid rgba(0, 0, 0, 0.6)',
        },
        '&$focused': {
            border: '0.5px solid rgba(0, 0, 0, 0.6)',
        },
    },
    elementComplete: {
        border: '1px solid #1FC29B',
    },
    elementError: {
        border: '1px solid #E44447',
    },
    cardDetailsWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 44,
    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    startedButtonRoot: {
        borderRadius: 5,
        height: 64,
        boxShadow: 'none',
        width: '100%',
        backgroundColor: '#006FF1',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    startedButtonLabel: {
        color: 'white',
        textTransform: 'none',
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '140%',
    },
    endIcon: {
        position: 'absolute',
        right: 22,
    },
    proceeding: {
        marginTop: 14,
        fontWeight: 'normal',
        fontSize: 14,
        color: 'rgba(0, 23, 51, 0.4)',
        lineHeight: '19.6px',
        textAlign: 'center',
    },
    linkButton: {
        minWidth: 0,
        padding: 0,
        margin: '0px 3px 2px 3px',
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    link: {
        textDecoration: 'none',
        textTransform: 'none',
        fontWeight: 'normal',
        fontSize: 14,
        color: 'rgba(0, 23, 51, 1)',
        lineHeight: '19.6px',
    },
});
