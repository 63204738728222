import { useEffect, useState } from 'react';

const useDebouncedSearch = (value: string | undefined, delay = 500): string => {
    const [debouncedValue, setDebouncedValue] = useState<string>(value || '');

    useEffect(() => {
        const handler = setTimeout(
            () => {
                setDebouncedValue(value || '');
            },
            !value ? 1 : delay,
        );

        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
};

export default useDebouncedSearch;
