import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../config/theme';

export const useStyles = makeStyles({
    input: {
        height: 45,
        boxSizing: 'border-box',
        fontSize: 13,
        color: '#15416D',
        lineHeight: '16px',
        fontWeight: 400,
        '&::placeholder': {
            color: '#8A8A8A',
            fontSize: 13,
            lineHeight: '16px',
            letterSpacing: '0.1px',
            fontWeight: 400,
        },
    },
    focused: {},
    notchedOutline: {
        border: '1px solid rgba(151,151,151,0.14)',
    },
    root: {
        backgroundColor: '#FFFFFF',
        '&$focused $notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.87)',
        },
        '&:hover $notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.87)',
        },
    },
    rootTextField: {
        backgroundColor: '#FFFFFF',
        borderRadius: '5px 0px 0px 5px',
        height: 64,
        paddingLeft: 12,
        flex: 1,
        maxWidth: 450,
        minHeight: 64,
        boxShadow: '0 0 3px 5px rgba(0,0,0,0.01)',
        fontSize: 18,
        '& > fieldset': {
            border: 'none !important',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    container: {
        '& > div > p': {
            marginTop: 20,
            marginLeft: 0,
            marginRight: 0,
            color: '#E44447 !important',
            fontWeight: 600,
            fontSize: 15,
            lineHeight: '20.25px',
        },
    },
});
