import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        width: '22px !important',
        height: '22px !important',
        backgroundColor: '#006FF1',
        border: '5px solid #C2DCFC !important',
    },
    circle: {
        width: 12,
        height: 12,
        borderRadius: '50%',
        margin: 0,
        zIndex: 10,
    },
    inactive: {
        width: 14,
        height: 14,
        border: '2px solid #ccc',
    },
    completed: {
        backgroundColor: '#006FF1',
        zIndex: 1,
        fontSize: 18,
    },
});
