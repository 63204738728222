// @ts-nocheck

// TODO typings
import React, { FC, useState } from 'react';

import {
    CardCvcElement,
    CardCvcElementProps,
    CardElement,
    CardElementProps,
    CardExpiryElement,
    CardExpiryElementProps,
    CardNumberElement,
    CardNumberElementProps,
} from '@stripe/react-stripe-js';
import { useField } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import CompletedIcon from '../../assets/images/completed-icon.svg';
import ErrorIcon from '../../assets/images/error-icon.svg';
import { useStyles } from './style';

type Props =
    | ({ name: string; stripeElement: typeof CardElement } & CardElementProps)
    | ({ name: string; stripeElement: typeof CardNumberElement } & CardNumberElementProps)
    | ({ name: string; stripeElement: typeof CardCvcElement } & CardCvcElementProps)
    | ({ name: string; stripeElement: typeof CardExpiryElement } & CardExpiryElementProps);

const FormikStripeElement: FC<Props> = ({
    name,
    stripeElement: StripeElement,
    onChange,
    onBlur,
    ...otherProps
}) => {
    const { error, touched } = useField(name)[1];
    const { setError, setTouched } = useField(name)[2];
    const [completed, setCompleted] = useState<boolean>(false);

    const classes = useStyles();
    return (
        <div className={classes.container}>
            <StripeElement
                onChange={event => {
                    setError(event.error?.message);
                    setCompleted(event.complete);
                    onChange(event.complete);
                }}
                onBlur={() => {
                    setTouched(true);
                }}
                {...otherProps}
            />
            {!!error && touched && (
                <FormHelperText classes={{ error: classes.errorText }} error>
                    {error}
                </FormHelperText>
            )}
            {!!error && touched && (
                <img src={ErrorIcon} alt="Error" width="3" height="13" className={classes.errorIcon} />
            )}
            {completed && (
                <img
                    src={CompletedIcon}
                    alt="Completed"
                    width="12"
                    height="8"
                    className={classes.statusIcon}
                />
            )}
        </div>
    );
};

export default FormikStripeElement;
