export default interface CompanyFinancialDataModel {
    error: boolean;
    errorString: string;
    currentYear: BalanceSheet;
    startDate: string;
    endDate: string;
    completedDate: string;
    newDocument: string;
    oldDocument: string;
    validForSubmission: boolean;
    employees: number;
    companyNumber: string;
    companyName: string;
    hasPreviousYear: boolean;
}

export interface BalanceSheet {
    year: string;
    fixedAssets: {
        value: number;
        fields: Record<string, number>;
    };
    currentAssets: {
        value: number;
        fields: Record<string, number>;
    };
    currentCreditors: {
        value: number;
        fields: Record<string, number>;
    };
    nonCurrentCreditors: {
        value: number;
        fields: Record<string, number>;
    };
    netAssets: {
        value: number;
        fields: Record<string, number>;
    };
    capitalAndReserves: {
        value: number;
        fields: Record<string, number>;
    };
}

export const defaultFinancialData: CompanyFinancialDataModel = {
    error: false,
    errorString: '',
    currentYear: {
        year: '',
        fixedAssets: { value: 0, fields: {} },
        currentAssets: { value: 0, fields: {} },
        currentCreditors: { value: 0, fields: {} },
        nonCurrentCreditors: { value: 0, fields: {} },
        netAssets: { value: 0, fields: {} },
        capitalAndReserves: { value: 0, fields: {} },
    },
    startDate: '',
    endDate: '',
    completedDate: '',
    newDocument: '',
    oldDocument: '',
    validForSubmission: false,
    employees: 0,
    companyNumber: '',
    companyName: '',
    hasPreviousYear: false,
};

export const balanceSheetFields: { [key: string]: { total: string[]; summedUp: string[] } } = {
    fixedAssets: {
        total: ['fixed assets'],
        summedUp: [
            'tangible assets',
            'intangible assets',
            'long-term investments',
            'long term investments',
            'investments in subsidiaries',
        ],
    },
    currentAssets: {
        total: ['current assets'],
        summedUp: [
            'cash at bank and in hand',
            'cash at bank and on hand',
            'cash',
            'accounts receivable',
            'accounts receivables',
            'called up share capital not paid',
            'trade debtors',
            'receivables',
            'other receivables',
            'other debtors',
            'prepayments',
            'accrued income',
            'prepayments and accrued income',
            'inventory',
            'stocks',
            'stock',
        ],
    },
    currentCreditors: {
        total: [
            'current liabilities',
            'creditors: amounts falling due within one year',
            'creditors amounts falling due within one year',
        ],
        summedUp: [
            'accounts payable',
            'accounts payables',
            'trade creditors',
            'creditors',
            'other creditors',
            'vat payable',
            'corporation tax',
            'accruals',
            'accrued expenses',
            'deferred income',
            'income in advance',
            'accruals and deferred income',
            'provision for corporation tax',
            'other payables',
        ],
    },
    netAssets: {
        total: ['net assets', 'net assets (liabilities)', 'total net assets (liabilities)'],
        summedUp: [],
    },
    nonCurrentCreditors: {
        total: [
            'non-current liabilities',
            'non current liabilities',
            'creditors: amounts falling due after one year',
            'creditors amounts falling due after one year',
        ],
        summedUp: ['long-term loans', 'long term loans', 'loans'],
    },
    capitalAndReserves: {
        total: [
            'capital and reserves',
            'equity',
            'total equity',
            'shareholders funds',
            "shareholders' funds",
            'shareholder funds',
            'total shareholder funds',
        ],
        summedUp: [
            'share capital',
            'capital',
            'shares',
            'ordinary shares',
            'preference shares',
            'called up share capital',
            'issued share capital',
            'retained earnings',
            'reserves',
            'profit and loss account',
            'profit and loss reserves',
            'dividends',
        ],
    },
};
