import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    grid: {},
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 40,
    },
    lastGridItem: {
        marginTop: -20,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: 540,
        flex: 1,
    },
    icon: {
        fontSize: 25,
        color: '#006FF1',
        marginBottom: -3,
    },
    pageTitle: {
        fontWeight: 700,
        fontSize: 36,
        lineHeight: '140%',
        color: '#001733',
    },
    transactionNumber: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '145%',
        marginTop: 28,
        color: '#006FF1',
    },
    thankYou: {
        fontWeight: 600,
        fontSize: 22,
        lineHeight: '140%',
        color: '#001733',
        marginTop: 14,
        marginBottom: 28,
    },
    confirmationEmail: {
        fontSize: 18,
        lineHeight: '145%',
        color: 'rgba(0, 23, 51, 0.5)',
    },
    email: {
        fontWeight: 600,
        color: '#006FF1',
    },
    getInTouch: {
        fontWeight: 700,
        textDecoration: 'none',
    },
});
