import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    accordionRoot: {
        marginTop: '20px !important',
        boxShadow: 'none',
        borderTopWidth: 0,
        backgroundColor: 'transparent',
        '&::before': {
            content: 'none',
        },
        [theme.breakpoints.up('md')]: {
            minHeight: 160,
            marginBottom: '30px !important',
        },
    },
    accordionExpanded: {
        margin: '0 !important',
    },
    accordionSummaryRoot: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        minHeight: '52px !important',
    },
    accordionSummaryContent: {
        flexGrow: 0,
    },
    accordionHeader: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '140%',
        color: 'rgba(0, 23, 51, 0.5)',
    },
    accordionDetails: {
        padding: 0,
    },
    accordionText: {
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: 14,
        lineHeight: '135%',
        textAlign: 'center',
        color: '#001733',
        [theme.breakpoints.down('sm')]: {
            marginTop: 15,
        },
    },
    linkIcon: {
        fontSize: 13,
        marginBottom: -3,
    },
    startedButtonRoot: {
        height: 64,
        width: 140,
        backgroundColor: '#006FF1',
        boxShadow: '0 0 3px 5px rgba(0,0,0,0.01)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
    },
    startedButtonLabel: {
        color: 'white',
        textTransform: 'none',
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '140%',
    },
}));
