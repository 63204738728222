import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../config/theme';

export const useStyles = makeStyles({
    advantageContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 40,
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            marginTop: 40,
            marginBottom: 0,
        },
    },
    advantageImage: {
        width: 22,
        height: 22,
        marginRight: 16,
    },
    advantageTextContainer: {
        maxWidth: 280,
    },
    advantageTitle: {
        fontWeight: 'bold',
        fontSize: 18,
        lineHeight: '140%',
        marginBottom: 5,
        marginTop: 0,
    },
    advantageDescription: {
        fontSize: 16,
        lineHeight: '130%',
    },
});
