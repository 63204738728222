import { createMuiTheme, Theme } from '@material-ui/core/styles';

export const theme: Theme = createMuiTheme({
    typography: {
        fontFamily: 'Source Sans Pro',
        h1: {
            fontWeight: 700,
            fontSize: 28,
            lineHeight: '140%',
            color: '#001733',
        },
    },
    palette: {
        background: {
            default: '#F1F6FF',
        },
        text: {
            primary: '#001733',
        },
        primary: {
            light: '#F8FBFF',
            main: '#007FFC',
            dark: '#3A9CEC',
        },
    },
});
