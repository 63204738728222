import React, { FC, useEffect, useState } from 'react';
import { Button, InputLabel, Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router';
import CustomFormikTextField from '../../components/CustomFormikTextField';
import { initialValues, validationSchema, ContactFormValues } from './config';
import { sendEmail } from '../../services/EmailService';
import { useStyles } from './styles';

const ContactPage: FC = () => {
    const classes = useStyles();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const [locationKey, setLocationKey] = useState<string>('');
    const [submitted, setSubmitted] = useState<boolean>(false);

    useEffect(() => {
        setLocationKey(location.key || '');
        setSubmitted(false);
    }, [location]);

    // @ts-ignore
    const handleSubmitForm = async (values: ContactFormValues, { resetForm }) => {
        if (await sendEmail(values)) {
            setSubmitted(true);
        } else
            enqueueSnackbar('We could not send your message, please try again later', { variant: 'error' });

        resetForm();
    };

    return (
        <div className={classes.container}>
            <Typography variant="h1" className={classes.pageTitle}>
                Contact us
            </Typography>

            <Typography variant="subtitle1" className={classes.subtitle}>
                Please get in touch if you have any questions and we will get back to you soon
            </Typography>

            <Formik
                key={locationKey}
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmitForm}
            >
                {({ handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit} className={classes.form}>
                            <div>
                                <InputLabel htmlFor="name" className={classes.inputLabel}>
                                    NAME
                                </InputLabel>
                                <CustomFormikTextField
                                    disableLabel
                                    fullWidth
                                    id="name"
                                    name="name"
                                    placeholder="Name"
                                />
                            </div>
                            <div>
                                <InputLabel htmlFor="email" className={classes.inputLabel}>
                                    EMAIL
                                </InputLabel>
                                <CustomFormikTextField
                                    disableLabel
                                    id="email"
                                    name="email"
                                    placeholder="example@example.com"
                                />
                            </div>
                            <div>
                                <InputLabel htmlFor="email" className={classes.inputLabel}>
                                    MESSAGE
                                </InputLabel>
                                <CustomFormikTextField
                                    disableLabel
                                    id="message"
                                    name="message"
                                    placeholder="Write you message here ..."
                                    multiline
                                    rows={7}
                                />
                            </div>
                            <div>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    classes={{ root: classes.button, label: classes.label }}
                                >
                                    Send message
                                </Button>
                                {submitted && (
                                    <Typography className={classes.submitted}>
                                        <DoneIcon className={classes.icon} />
                                        Thank you – we will be in touch soon.
                                    </Typography>
                                )}
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default ContactPage;
