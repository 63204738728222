import { FC, useContext, useState } from 'react';
import { Button, Collapse, Drawer, Hidden, List, ListItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './styles';
import getStaticContent from '../StaticContent';
import MenuContext from '../../../components/MenuContext';

const MenuDrawer: FC = () => {
    const classes = useStyles();
    const { openTooltips, closeAllTooltips } = useContext(MenuContext);

    const [drawerOpen, setDrawerOpen] = useState<boolean>(openTooltips.some(t => t));
    const [collapseOpen, setCollapseOpen] = useState<boolean[]>([false, false, false, false, false]);

    const openCollapse = (key: number) => {
        setCollapseOpen([
            ...collapseOpen.map((collapse: boolean, index: number) => key === index && !collapseOpen[key]),
        ]);
    };

    const tooltips = getStaticContent(() => openCollapse(0));

    return (
        <Hidden mdUp>
            <Button disableRipple color="inherit" onClick={() => setDrawerOpen(true)} id="open-drawer">
                <MenuIcon fontSize="large" />
            </Button>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => {
                    setDrawerOpen(false);
                    closeAllTooltips();
                }}
                classes={{ paper: classes.drawerPaper }}
            >
                <div className={classes.menuHeader}>
                    <NavLink to="/" className={classes.titleLink} onClick={() => setDrawerOpen(false)}>
                        <Typography className={classes.menuHeaderText}>
                            just <strong>dormant</strong> accounts.
                        </Typography>
                    </NavLink>

                    <Button
                        className={classes.closeButton}
                        onClick={() => {
                            setDrawerOpen(false);
                            closeAllTooltips();
                        }}
                    >
                        <CloseIcon className={classes.closeButtonIcon} />
                    </Button>
                </div>

                <List component="nav" aria-label="secondary mailbox folders">
                    {tooltips.map((tooltip, key: number) => (
                        <ListItem key={key} className={classes.listItem}>
                            {!tooltip.path ? (
                                <>
                                    <Button
                                        className={classes.button}
                                        disableRipple
                                        variant="text"
                                        onClick={() => openCollapse(key)}
                                    >
                                        <Typography className={classes.listItemText}>
                                            {tooltip.name}
                                        </Typography>
                                    </Button>

                                    <Collapse
                                        in={collapseOpen[key] || openTooltips[key]}
                                        collapsedHeight={0}
                                        className={classes.collapse}
                                    >
                                        <div className={classes.collapseContent}>{tooltip.element}</div>
                                    </Collapse>
                                </>
                            ) : (
                                <Button
                                    className={classes.button}
                                    disableRipple
                                    variant="text"
                                    component={NavLink}
                                    to={tooltip.path}
                                    onClick={() => setDrawerOpen(false)}
                                >
                                    <Typography className={classes.listItemText}>{tooltip.name}</Typography>
                                </Button>
                            )}
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </Hidden>
    );
};

export default MenuDrawer;
