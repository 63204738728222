import CompanyFinancialDataModel, { defaultFinancialData } from './CompanyFinancialData.model';

export default interface Company {
    company_number: string;
    title: string;
    authCode?: string;
    payment: {
        cardEnding: string;
        transactionId: string;
        amount: string;
        cardType: string;
        email: string;
        yearEnd: string;
    };
    financialData: CompanyFinancialDataModel;
    financialDataLoaded: boolean;
}

export const initialCompanyValue = {
    company_number: '',
    title: '',
    authCode: '',
    payment: { transactionId: '', amount: '', cardEnding: '', cardType: '', email: '', yearEnd: '' },
    financialData: defaultFinancialData,
    financialDataLoaded: false,
};

export interface CompanyDetails {
    accounts: {
        last_accounts: {
            due_on: string; // "2022-12-19",
            period_start_on: string; // "2021-03-19",
            period_end_on: string; // : '2022-03-31';
            overdue: boolean;
        };
        next_accounts: {
            due_on: string; // "2022-12-19",
            period_start_on: string; // "2021-03-19",
            period_end_on: string; // : '2022-03-31';
            overdue: boolean;
        };
        accounting_reference_date: {
            month: string;
            day: string;
        };
    };
    company_name: string;
    company_number: string;
}

export interface PersonOfInterest {
    items: [
        {
            name: string; // 'Mr Edward Vincent Abbott';
        },
    ];
}
