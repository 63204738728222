import React, { FC, useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import CompanyFinancialDataModel from '../../models/CompanyFinancialData.model';
import { getCompanyFinancialData } from '../../services/FinancialDataService';
import { useStyles } from './styles';
import BalanceSheet from './BalanceSheet';
import FinancialDataConfirmation from './FinancialDataConfirmation';
import CompanyContext from '../../components/CompanyContext';

const FinancialPage: FC = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { company, setCompany } = useContext(CompanyContext);

    const [noData, setNoData] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [financialData, setFinancialData] = useState<CompanyFinancialDataModel>();

    useEffect(() => {
        if (company.company_number && !company.financialDataLoaded) {
            getCompanyFinancialData(company.company_number, company.title).then(
                (financialData: CompanyFinancialDataModel) => {
                    if (!financialData.error) {
                        setCompany({ ...company, financialData, financialDataLoaded: true });
                    } else {
                        if (financialData.errorString !== '') {
                            enqueueSnackbar(financialData.errorString, { variant: 'error' });
                            setError(true);
                        }
                        setNoData(true);
                        setFinancialData(financialData);
                    }
                },
            );
        } else if (company.financialDataLoaded) {
            setFinancialData(company.financialData);
        }
    }, [company, setCompany, enqueueSnackbar]);

    return (
        <Grid container className={classes.gridContainer}>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={4} className={classes.leftColumn}>
                <BalanceSheet company={company} financialData={financialData} />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={5} xl={4} className={classes.rightColumn}>
                <FinancialDataConfirmation
                    canSubmit={financialData?.validForSubmission}
                    error={error}
                    noData={noData}
                    date={company.financialData.endDate}
                    financialData={financialData}
                />
            </Grid>
        </Grid>
    );
};

export default FinancialPage;
