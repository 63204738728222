import React, { FC, useContext } from 'react';
import { Button } from '@material-ui/core';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import OpenInNew from '@material-ui/icons/OpenInNew';
import { useStyles } from './styles';
import CustomTooltip from '../../components/CustomTooltip';
import AuthFormikTextField from './AuthFormikTextField';
import { initialValues, validationSchema, SearchForm } from './config';
import CompanyContext from '../../components/CompanyContext';
import InfoIcon from '../../assets/images/info-icon.svg';

const AuthenticationPage: FC = () => {
    const classes = useStyles();
    const history = useHistory();

    const { company, setCompany } = useContext(CompanyContext);

    const tooltipContent = () => (
        <>
            {`It is a 6 digit alphanumeric code needed to file online and is the equivalent of a
                        company officer’s signature. You will have received an Authentication Code by letter
                        from Companies House when you had set up your company. If you don’t know it or lost
                        it, you can request a new one from the `}
            <a
                target="_blank"
                rel="noreferrer"
                href="https://www.gov.uk/guidance/company-authentication-codes-for-online-filing"
            >
                government website <OpenInNew className={classes.linkIcon} />
            </a>
        </>
    );

    const renderButton = () => (
        <Button
            type="submit"
            variant="contained"
            color="primary"
            classes={{ root: classes.startedButtonRoot, label: classes.startedButtonLabel }}
        >
            Next
        </Button>
    );

    const handleSubmitForm = (values: SearchForm) => {
        if (values.authCode) {
            setCompany({ ...company, authCode: values.authCode });
            history.push('/payments');
        }
    };

    const renderForm = () => (
        <Formik
            enableReinitialize
            initialValues={company.authCode ? { authCode: company.authCode } : initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitForm}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className={classes.form}>
                    <AuthFormikTextField disableLabel name="authCode" placeholder="123456" />
                    {renderButton()}
                </form>
            )}
        </Formik>
    );

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.headerWrapper}>
                    <Typography variant="h1" className={classes.pageTitle}>
                        Authentication Code
                    </Typography>
                    <CustomTooltip interactive placement="right-start" title={tooltipContent()}>
                        <img src={InfoIcon} alt="Info" width="16" height="16" className={classes.infoIcon} />
                    </CustomTooltip>
                </div>
                <Typography className={classes.description}>
                    Please input your authentication code below.
                </Typography>
            </div>
            {renderForm()}
        </div>
    );
};

export default AuthenticationPage;
