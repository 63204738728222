import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: '#F5F9FF',
        boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.05)',
        borderRadius: 8,
        [theme.breakpoints.up('sm')]: {
            minWidth: 400,
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: 0,
        },
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 400,
        padding: '35px !important',
        [theme.breakpoints.down('sm')]: {
            padding: '10px !important',
        },
    },
    icon: {
        color: '#8AC0FF',
        width: 40,
        height: 40,
        marginBottom: 25,
        [theme.breakpoints.down('sm')]: {
            marginTop: 25,
        },
    },
    error: {
        color: 'red',
    },
    text: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        textAlign: 'center',
        lineHeight: '140%',
        color: '#001733',
    },
    textBlue: {
        color: '#006FF1',
        fontWeight: 600,
    },
    buttonsWrapper: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' },
    buttonRoot: {
        width: 120,
        height: 50,
        backgroundColor: '#006FF1',
        borderRadius: 5,
        marginTop: 50,
        [theme.breakpoints.down('sm')]: {
            marginTop: 30,
            marginBottom: '20px !important',
        },
    },
    buttonLabel: {
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '140%',
        color: '#FFFFFF',
        textTransform: 'none',
    },
}));
